<script>
import LoginComponent from "@/components/authentication/loginComponent.vue";
import SignupComponent from "@/components/authentication/signupComponent.vue";
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from 'vue-router';

export default {
  name: "LoginUser",
  components: {
    LoginComponent,
    SignupComponent,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const type = ref("login");
    const indicatorClass = ref("signin-indicator");
    const switchTab = (data) => {
      type.value = data;
      indicatorClass.value = data + "-indicator"
      console.log(indicatorClass.value)
      if (type.value == "signup") {
        router.push("/signup")
      } else {
        router.push("/login")
      }
    };
  
    const signinActive = computed(() =>
      type.value == "login" ? "active" : "none"
    );
    const signupActive = computed(() =>
      type.value == "signup" ? "active" : "none"
    );

    onMounted(async () => {
      await router.isReady()
      console.log(route.path)
      if (route.path) {
        console.log(route.path.substring(1))
        switchTab(route.path.substring(1))
      }
    })

    return {
      switchTab,
      type,
      signupActive,
      signinActive,
      indicatorClass,
    };
  },
};
</script>

<template>
  <div id="container" style="height: 100vh;">
    <div class="row" style="height: auto;">
      <div class="col-image-mobile">
        <img src="assets/images/rodeo-logo.png" style="width: 50vi; height: 50vi;"/>
      </div>
    </div>
    <div class="row" >
      <div class="col-image-web" style="align-self: center;">
        <img src="assets/images/rodeo-logo.png" style="height: auto;width: 50vi; align-self: center; align-items: center; padding-top: 5vi;"/>
      </div>
      <div class="col-form">
        <div class="tabs">
          <div
            :class="'tab-signin tab-button ' + signinActive"
            @click="switchTab('login')"
          >
            SIGN IN
          </div>
          <div
            :class="'tab-signup tab-button ' + signupActive"
            @click="switchTab('signup')"
          >
            SIGN UP
          </div>
          <span :class="indicatorClass"></span>
        </div>
        <div class="content">
          <keep-alive>
            <login-component v-if="type == 'login'" />
          </keep-alive>
          <keep-alive>
            <signup-component
              @go-to-login="type = 'login'"
              v-if="type == 'signup'"
            />
          </keep-alive>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#container {
  /* color: #141414; */
  background-color: #cccccc;
  height: 1000px;  
  width: 100%!important;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.row {
  display: flex;
  flex-direction: row;
  height: 100%;
}


.col-image-web {
    width:50%;
    height: 100%;
}
.col-image-mobile {
    height: 0%;
    width: 0%;
    display: none;
}
.col-form {
  padding: 8%;
  background-color: #cccccc;
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 1280px) {
  .col-image-web {
    width:0%;
  }
  .col-image-mobile {
    width:100%;
    height: auto;
    display: flex;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .col-image {
    width:0%;
  }
  .col-form {
    padding: 8%;
    background-color: #cccccc;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.tabs {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;
}
.tab-button:hover {
  cursor: pointer;
}
.tab-button {
  position: relative;
  font-weight: 600;
}
.tab-signin {
  padding: 10px;
  width: 50%;
  text-align: center;
}

.none {
  color: #000;
  background: #fff;
}
.active {
  background: var(--RODEONOW_RED);
  color: #fff;
}
.tab-signup {
  padding: 10px;
  width: 50%;
  text-align: center;
}
.signin-indicator {
  position: absolute;
  width: 40px;
  height: 28px;
  left: 50px;
  margin: auto;
  z-index: 999;
  bottom: -33px;
  border-bottom: solid 30px rgb(255, 255, 255);
  border-left: solid 30px transparent;
  border-right: solid 30px transparent;
}
.signup-indicator {
  position: absolute;
  width: 40px;
  height: 28px;
  right: 50px;
  margin: auto;
  z-index: 999;
  bottom: -33px;
  border-bottom: solid 30px rgb(255, 255, 255);
  border-left: solid 30px transparent;
  border-right: solid 30px transparent;
}

.content {
  margin-top: 20px;
  background: #fff;
  width: 100%;  
  max-height: 100vh;
}
</style>
