import { reactive } from "vue";

export const handlers = reactive({
  selectedAccessUser: null,
  scoreTime: "score",
  selectedEvent: null,
  selectedAnimal: null,
  selectedContestant: null,
  score: null,
  time:null,
  inputCustomAnimal : {
    animal_name : null,
    animal_brand: null,
    contractor_name: null,
  }
});
