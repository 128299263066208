<!-- eslint-disable vue/multi-word-component-names -->

<template>
  <v-card v-if="showCard" class="video-card">
    <v-card-text class="d-flex align-center justify-space-between py-2 px-2">
      <div class="d-flex align-center" style="max-width: 60%">
        <template v-if="!userVideo">
          <v-skeleton-loader type="avatar" style="margin-left: -8px"></v-skeleton-loader>
          <v-skeleton-loader type="list-item-two-line" style="margin-left: -16px" width="200"></v-skeleton-loader>
        </template>

        <template v-else>
          <router-link :to="{
    name: 'uniqueRodeo',

    params: {
      id: userVideo.id ?? $store.state.selectedProfile.id,
    },
  }" class="ml-2">
            <v-avatar size="48" color="transparent">
              <img crossorigin style="height: 56px; width: auto"
                v-if="userVideo.photo_url && userVideo.photo_url.length > 0" :src="userVideo.photo_url" />
            </v-avatar>
          </router-link>
          <div class="d-flex flex-column ml-4">
            <template v-if="userVideo.first_name || userVideo.last_name">
              <router-link :to="{
    name: 'uniqueRodeo',
    params: {
      id: userVideo.id ?? $store.state.selectedProfile.id,
    },
  }">
                <span>{{
    `${userVideo.first_name} ${userVideo.last_name}`
  }}</span>
              </router-link>
            </template>

            <template v-else>
              <span class="text-caption">
                {{
      `${$store.state.first_name} ${$store.state.selectedProfile.last_name}`
    }}
              </span>
            </template>
            <span v-if="video.animal_name" class="text-caption">
              <span v-if="video.animal_brand" class="text-medium-emphasis mr-1">
                {{ video.animal_brand }}
              </span>
              <span class="text-high-emphasis">
                {{ video.animal_name }}
              </span>
            </span>
            <span>
              {{ video.title }}
            </span>
          </div>
        </template>
      </div>
      <iframe ref="downloadFrame" style="display: none"></iframe>
      <div class="d-flex flex-column">
        <div class="d-flex justify-space-between align-center">
          <span class="mr-1">{{ videoDate }}</span>
          <v-menu v-model="menu" :close-on-content-click="false" location="end">

            <template v-slot:activator="{ props }">
              <v-btn fab icon size="small" variant="text" v-bind="props">
                <v-icon icon="fas fa-ellipsis"></v-icon>
              </v-btn>
            </template>
            <v-list v-if="isMy">
              <v-btn @click="copyVideoLink" variant="text" block class="text-black">Copy Link</v-btn>
              <v-divider></v-divider>
              <embed-modal :video="video" />
              <v-divider></v-divider>
              <v-btn @click="download" variant="text" block class="text-black">Download</v-btn>
              <v-divider></v-divider>
              <v-btn @click="editVideo" variant="text" block class="text-black">Edit</v-btn>
              <v-divider></v-divider>
              <v-btn @click="deleteVideo" variant="text" block class="text-red">Delete</v-btn>
              <v-divider></v-divider>

              <v-btn @click="reportVideo" variant="text" block class="text-black">Report</v-btn>
            </v-list>
            <v-list v-else>
              <v-btn @click="copyVideoLink" variant="text" block class="text-black">Copy Link</v-btn>
              <v-divider></v-divider>
              <v-btn @click="download" variant="text" block class="text-black">Download</v-btn>
              <v-divider></v-divider>
              <embed-modal :video="video" />
              <v-divider></v-divider>
              <v-btn @click="reportVideo" variant="text" block class="text-black">Report</v-btn>
            </v-list>
          </v-menu>
        </div>

        <div class="d-flex align-center" style="position: relative">
          <v-icon color="primary" icon="fas fa-map-marker-alt" size="small"
            style="position: absolute; top: 0; left: -22px" />
          <div :title="video.location">
            {{ video.location }}
            <!-- {{ video.location.slice(0, 20)
            }}{{ video.location.length > 20 ? "..." : "" }} -->
          </div>
        </div>
      </div>
    </v-card-text>

    <div style="position: relative">
      <img crossorigin="anonymous" @click="playVideo" :src="video.thumbnail_url"
        :style="{ 'object-fit': 'cover', width: '100%' }" height="600" width="300" style="cursor: pointer" />
      <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 1;">
        <img src="/assets/icons/glyph/glyphs/play.circle.png" @click="playVideo" width="40" class="play-icon"
          alt="Play Icon" />
      </div>
      <div style="position: absolute; bottom: 0; left: 0" class="d-flex justify-space-between w-100 pb-3 px-3">
        <div class="pa-2 rounded text-white text-subtitle-2" style="background-color: rgba(0, 0, 0, 0.5)">
          {{ views }} views
        </div>
        <div class="pa-2 rounded text-white text-subtitle-2" style="background-color: rgba(0, 0, 0, 0.5)">
          {{ duration }}
        </div>
      </div>
    </div>
  </v-card>
</template>

<script setup>
import store from "@/store";
import { ref, computed, defineProps, defineEmits, onMounted, onBeforeUnmount, onBeforeUpdate } from "vue";
import dayjs from "dayjs";
import durationPlugin from "dayjs/plugin/duration";
import axios from 'axios';
import { onBeforeRouteLeave } from 'vue-router';

import {
  getStorage,
  getDownloadURL,
  ref as storageRef,
} from "firebase/storage";
import {
  doc,
  addDoc,
  getDoc,
  deleteDoc,
  getFirestore,
} from "firebase/firestore";
import { getDatabase, ref as dbRef, onValue } from "firebase/database";

import EmbedModal from "@/components/embedModal.vue";
import { computedAsync, useClipboard } from "@vueuse/core";
import { getProfileImageById } from "@/services/profiles";
import { useStepState } from "@/store/uploadVideo/step.js";
import router from "@/router";
import mixpanel from "mixpanel-browser";
import { EngamentEvent } from "@/utils/event_enum";
import events from "@/utils/events";

dayjs.extend(durationPlugin);

const props = defineProps(["video"]);
const emit = defineEmits(["delete"]);

const menu = ref(null);
const storage = getStorage();
const downloadFrame = ref();
const db = getFirestore();
const views = ref(0);
const stepState = useStepState();
const { step, getStep } = stepState;

const duration = computed(() => {
  const seconds = Number(props.video.duration).toFixed(0);

  return dayjs.duration(seconds, "seconds").format("m:ss");
});

const realtimeDB = getDatabase();
const videoViews = dbRef(realtimeDB, `videoViews/${props.video.video_id}`);

const showCard = ref(true);

onMounted(() => {
  window.addEventListener('popstate', handlePopstate);
});

onBeforeUnmount(() => {
  window.removeEventListener('popstate', handlePopstate);
});

const handlePopstate = (event) => {
  console.log('popstate event triggered');
  if (showCard.value) {
    showCard.value = false;
  }
};

onValue(videoViews, (snapshot) => {
  const data = snapshot.val();
  if (data != null) {
    views.value = data;
  }
});

function playVideo() {
  store.commit("SET_MODAL_VIDEO", props.video);
  store.commit("VIDEO_PLAYER_MODAL", true);


}

const isMy = computed(() => {
  if (store.state.selectedProfile.id != null || store.state.selectedProfile.id != undefined) {
    return userVideo.value.id === store.state.selectedProfile.id;

  }
  return false;
});

const videoDate = computed(() => {
  let endString = "N/A";

  try {
    endString = dayjs(props.video.event_date * 1000).format("MMMM DD, YYYY");
  } catch (error) {
    endString = props.video.event_date;
  }

  return endString;
});

const userVideo = computedAsync(() => {
  let docRef = getDoc(doc(db, "users", props.video.user_id)).then(
    async (doc) => {
      return {
        ...doc.data(),
        id: doc.id,
        photo_url: await getProfileImageById({
          id: doc.id,
          account_type: doc.data().account_type,
        }),
      };
    }
  );

  return docRef;
}, null);
function updateUserMap(userId, value) {
  if (localStorage) {
    let userMap = JSON.parse(localStorage.getItem('userMap')) || {};


    if (userMap.hasOwnProperty(userId)) {
      userMap[userId] += value;
    } else {
      userMap[userId] = value;
    }

    console.log(userMap);

    mixpanel.track(EngamentEvent.video_download, {
      "videos downloaded": userMap[userId],
      "source": store.state.selectedTab,
      "Video Owner": props.video.user_name,
      "Animal in video": props.video.animal_name,
      "Rodeo event": events[props.video.event_type - 1],
      "my_video": props.video.user_id == store.state.userProfile.id,
      "video_event": props.video.event_type,
      "user_id": store.state.userProfile.id,
      "video_id": props.video.video_id,

    })

    localStorage.setItem('userMap', JSON.stringify(userMap));
  } else {
    console.error('LocalStorage is not supported in this browser.');
  }
}
async function download() {
  if (
    confirm("Are you sure you want to download this video to your computer?")
  ) {

    const url = await getDownloadURL(
      storageRef(storage, `videos/${props.video.video_id}.mov`)
    ).catch((error) => {
      console.error(error);
      return "";
    });

    updateUserMap(store.state.userProfile.current_accessed_account, 1)

    axios.get(url, { responseType: 'blob' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'video/mp4' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        const timestamp = new Date(props.video.event_date * 1000);
        const month = String(timestamp.getMonth() + 1).padStart(2, '0');
        const day = String(timestamp.getDate()).padStart(2, '0');
        const year = timestamp.getFullYear();
        link.download = `${props.video.title}-${props.video.location}-${month}-${day}-${year}.mov`
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(console.error)
    console.log(url)
  }
}
function deleteVideo() {
  if (
    confirm(
      "Are you sure you want to delete this video? This action cannot be undone."
    )
  ) {
    return deleteDoc(doc(db, "videos", props.video.id))
      .then(() => {
        emit("deleted");
      })
      .catch(console.error);
  }
}



function editVideo() {
  store.commit("SET_VIDEO_TO_EDIT", props.video);
  store.commit("SET_IS_VIDEO_EDIT", true);
  console.log("VIDEO_EDIT", props.video);
  router.push({ name: "editVideo", params: { id: store.state.videoToEdit.video_id } });
  stepState.step.value = 1;

}
function reportVideo() {
  if (confirm("Are you sure you want to report this video?")) {
    return addDoc(doc(db, "reported_videos"), {
      reported_date: new Date(),
      user_id: props.video.user_id,
      video_id: props.video.video_id,
    }).catch(console.error);
  }
}

function copyVideoLink() {


  const source = `${window.location.origin}/video/${props.video.video_id}`;

  const { copy, isSupported } = useClipboard({ source, legacy: true });

  if (isSupported.value) {
    copy();
    var isMyVideo = props.video.userId == store.state.userProfile.id;
    var user_id;
    if (!isMyVideo) {
      user_id = props.video.user_id;
    }
    let userMap = JSON.parse(localStorage.getItem('countShare')) || {};


    if (userMap.hasOwnProperty(user_id)) {
      userMap[user_id] += 1;
    } else {
      userMap[user_id] = 1;
    }

    localStorage.setItem('userMap', JSON.stringify(userMap));
    mixpanel.track(EngamentEvent.share, {
      "Network": "Copy link",
      "Number of shares": userMap[user_id],
      "Source Screen": store.state.selectedTab,
    })
  } else {
    navigator.clipboard
      .writeText(source)
      .then(() => {
        alert("Copied!");

      })
      .catch((error) => {
        alert(`Copy failed! error:${error}`);
      });
  }

  // if (window.isSecureContext && navigator.clipboard) {
  //   setTimeout(async () => {
  //     await navigator.clipboard.writeText(source);
  //   });
  // } else {
  //   unsecuredCopyToClipboard(url);
  // }
}
// function unsecuredCopyToClipboard(text) {
//   urlInput.value.value = text;
//   urlInput.value.focus();
//   urlInput.value.select();
//   urlInput.value.setSelectionRange(0, 99999);
//   try {
//     document.execCommand("copy");
//   } catch (err) {
//     console.error("Unable to copy to clipboard", err);
//   }
// }
</script>

<style>
.video-card {
  overflow: hidden;
}

.video-card:hover img {
  cursor: pointer;
}

.video-card:hover .play-icon {
  transform: scale(1.1);
}

.play-icon {
  display: block;
  filter: invert();
}

.v-theme--light a {
  text-decoration: none;
  color: black;
}
</style>
