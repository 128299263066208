 const UploadVideoEvent = {
  OPEN_VIDEO_UPLOAD: 'Video Upload Form Opened',
  FORM_OPENED:"Video Upload Form Opened",
  CLOSE_VIDEO_UPLOAD: 'Video Upload Discard Attempted',
  CLOSE_VIDEO_UPLOAD_YES: 'Video Upload Discard Success',
  CLOSE_VIDEO_UPLOAD_NO: 'Video Upload Discard Failure',
  TAP_SAVE_UPLOAD: 'Video Upload Save Attempted',
  TAP_SAVE_UPLOAD_YES: 'Video Upload Save Confirmed',
  TAP_SAVE_UPLOAD_NO: 'Video Upload Save Aborted',
  UPLOAD_SUCCESS: 'Video Upload Save Success',
  UPLOAD_FAILURE: 'Video Upload Save Failed',
  SAVE_FOR_LATER: 'Video Upload Saved For Later ',
};

export default UploadVideoEvent;

export const SearchEvent = {
  CLICK_CATEGORY : 'Click Category',
  RUN_SEARCH : 'Run Search',
};

export const  SignUpEvent =  {
   SELECT_ACCOUNT_TYPE : "Create Account Select Account Type",
    OPEN_REG_INFORMATION : "Create Account Registration Info Open",
    OPEN_FINAL_REG : "Create Account Registration Final Step Opened",
    FINISH_SIGN_UP : "Create Account Registration Finished",
    SIGN_UP_SUCCESS : "Create Account Success",
    SIGN_UP_FAILURE : "Create Account Error",
    REG_SUBMITED: "Create Account Registration Info Submitted",
}

export const EngamentEvent = {
  watch_video: 'Watch Video',
  next_previous_video: 'Next Previous Video',
  exit_video: 'Exit Video',
  video_download: 'Video Download',
  switch_tab: 'Menu Tab',
  feed_filter_opened: 'Feed Filter Opened',
  feed_filter_changed: 'Feed Filter Changed',
  click_category: 'Click Category',
  open_search: 'Open Search',
  search_result_click: 'Search Result Click',
  viewed: 'Viewed',
  followed: 'Followed',
  unfollowed: 'Unfollowed',
  share: 'Share'
}
