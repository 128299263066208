import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getFirestore } from "firebase/firestore";
import 'firebase/compat/storage';
import { getDatabase, ref, onValue } from "firebase/database";
import {
  getRemoteConfig,
  fetchAndActivate,
  getValue,

} from 'firebase/remote-config';

import store from "@/store";


const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};


const app = firebase.initializeApp(firebaseConfig);

const db = firebase.firestore(app);
const storage = firebase.storage(app).ref()
const database = getDatabase(app);

export { db, storage, database }
