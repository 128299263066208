import { createRouter, createWebHistory } from "vue-router";
import { getAuth, applyActionCode } from "firebase/auth";
import PortalHome from "../views/portal/PortalHome.vue";
import { useStore } from "vuex";
import { db } from "../plugins/firebase";
const store = useStore();
const auth = getAuth();

// We are going to use this function with the hook beforeEnter in each route that requires Auth.
function authGuard(to, from, next) {
  const user = auth.currentUser;
  if (!user && to.path !== "/") { 
    localStorage.setItem("historyPath", to.fullPath)
    return next("/login");
  }
  next();
}

function authedGuard(to, from, next) {
  const user = auth.currentUser;
  if (user && (to.path == "/login" || to.path == "/signup" || to.path == "/")) {
    return next("/feed");
  } else {
    return next();
  }
}

function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }
  console.log(userAgent)
  return "unknown";
}

function getQueryParam(url, param) {
  const urlParams = new URLSearchParams(url.split('?')[1]);
  return urlParams.get(param);
}

function checkAction(to, from, next) {
  const os = getMobileOperatingSystem();
  if (os === "iOS" || os == "Android") {
    const mode = getQueryParam(to.fullPath, 'mode');
    const oobCode = getQueryParam(to.fullPath, 'oobCode');
    const apiKey = getQueryParam(to.fullPath, 'apiKey');
    const videoId = getQueryParam(to.fullPath, 'videoID');
    const postId = getQueryParam(to.fullPath, 'postID');
    const deeplink = process.env.NODE_ENV == "production" ? "https://rodeonowpro.page.link" : "https://rodeonow.page.link"
    const url = `${deeplink}?link=${deeplink}/rodeo?apiKey%3D${apiKey}%26videoID%3D${videoId}%26postID%3D${postId}%26mode%3D${mode}%26oobCode%3D${oobCode}%26continueUrl%3Dhttps://rodeonow.page.link%26lang%3Den&apn=com.rodeonow.rodeonow&amv=1.0`
    window.location = url
  } else {
    processAction(to.fullPath, next)
  }
}

function processAction(path, next) {
  const mode = getQueryParam(path, 'mode');
  if (mode === 'verifyEmail') {
    verifyEmail(path, next);
  } else if (mode == 'video') {
    const videoId = getQueryParam(to.fullPath, 'videoID');
    next({ path: `/video/${videoId}`})
  } else {
    next({ path: '/' }); 
  }
}

function verifyEmail(fullPath, next) {
  const oobCode = getQueryParam(fullPath, 'oobCode');
  if (oobCode) {
    console.log(oobCode)
    applyActionCode(auth, oobCode)
      .then(() => {
        alert('Verify email successfully');
        next({ path: '/' }); 
      })
      .catch((error) => {

        next({ path: '/' }); 
      });
  } else {
    alert('Invalid verification link.');
    next({ path: '/' }); 
  }
}

const routes = [
  {
    path: "/action",
    name: "Action",
    meta: {
      blankPage: true
    },
    beforeEnter: checkAction
  },
  {
    path: "/",
    name: "landingPage",
    component: require("../views/LandingPage.vue").default,
    beforeEnter: authedGuard,
    meta: {
      landingPage: true
    }
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: require("../views/PrivacyPolicy.vue").default,
    meta: {
      blankPage: true
    }
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: require("../views/TermsAndConditions.vue").default,
    meta: {
      blankPage: true
    }
  },
  {
    path: "/auth",
    name: "authentication",
    component: require("../views/authentication/index.vue").default,
    beforeEnter: authedGuard,
    meta: {
      sideBar: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: require("../views/authentication/index.vue").default,
    beforeEnter: authedGuard,
  },
  {
    path: "/signup",
    name: "signup",
    component: require("../views/authentication/index.vue").default,
    beforeEnter: authedGuard,
  },
  {
    path: "/search",
    name: "search",
    component: require("../views/portal/Search.vue").default,
    beforeEnter: authGuard,
    meta: {
      sideBar: true,
      requiresAuth: true,
    },
  },
  {
    path: "/feed",
    name: "feed",
    component: require("../views/portal/Feed.vue").default,
    beforeEnter: authGuard,
    meta: {
      sideBar: true,
      requiresAuth: true,
    },
  },
  {
    path: "/top-rides", 
    name: "top-rides",
    component: require("../views/portal/TopRides.vue").default,
    beforeEnter: authGuard,
    props: { isTopRides: true },
    meta: {
      sideBar: true,
      requiresAuth: true,
    },
  },
  {
    path: "/portal",
    name: "portal",
    component: require("../views/home/portal.vue").default,
    beforeEnter: authGuard,
    meta: {
      sideBar: true,
      requiresAuth: true,
    },
  },
  {
    path: "/upload",
    name: "Upload",
    component: require("../views/UploadVideo.vue").default,
    beforeEnter: authGuard,
    meta: {
      sideBar: true,
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: require("../views/profile/index.vue").default,
    beforeEnter: authGuard,
    meta: {
      sideBar: true,
      requiresAuth: true,
    },
    children: [
      {
        path: "edit",
        name: "editProfile",
        component: require("../views/profile/edit.vue").default,
        beforeEnter: authGuard,
        meta: {
          sideBar: true,
          requiresAuth: true,
        },
      },
      {
        path: "change-password",
        name: "changePassowrd",
        component: require("../views/ChangePassword.vue").default,
        beforeEnter: authGuard,
        meta: {
          sideBar: true,
          requiresAuth: true,
        },
      },
      {
        path: "grant-access",
        name: "grantAccess",
        component: require("../views/grantAccess.vue").default,
        beforeEnter: authGuard,
        meta: {
          sideBar: true,
          requiresAuth: true,
        },
      },
      {
        path: "event",
        name: "Event",
        component: require("../views/portal/Event.vue").default,
        beforeEnter: authGuard,
        meta: {
          sideBar: true,
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/animals",
    name: "animals",
    component: require("../views/animals/index.vue").default,
    // beforeEnter: authGuard,
    meta: {
      sideBar: true,
      // requiresAuth: true,
    },
    children: [
      {
        path: ":id",
        name: "oneAnimal",
        component: require("../views/animals/animals.vue").default,
        // beforeEnter: authGuard,
        meta: {
          sideBar: true,
          // requiresAuth: true,
        },
      },
      {
        path: "edit/:id",
        name: "editAnimal",
        component: require("../views/animals/form.vue").default,
        beforeEnter: authGuard,
        meta: {
          sideBar: true,
          requiresAuth: true,
        },
      },
      {
        path: "new",
        name: "newAnimal",
        component: require("../views/animals/form.vue").default,
        beforeEnter: authGuard,
        meta: {
          sideBar: true,
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/my-rodeo",
    name: "myrodeo",
    component: require("../views/portal/MyRodeo.vue").default,
    // beforeEnter: authGuard,
    meta: {
      sideBar: true,
      // requiresAuth: true,
    },
    children: [
      {
        path: ":id",
        name: "uniqueRodeo",
        component: require("../views/portal/MyRodeo").default,
        // beforeEnter: authGuard,
        meta: {
          sideBar: true,
          // requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/my-rodeo/user",
    name: "myrodeo-user",
    component: require("../views/portal/MyRodeo.vue").default,
    // beforeEnter: authGuard,
    meta: {
      sideBar: true,
      // requiresAuth: true,
    },
    children: [
      {
        path: ":id",
        name: "uniqueRodeoUser",
        component: require("../views/portal/MyRodeo").default,
        // beforeEnter: authGuard,
        meta: {
          sideBar: true,
          // requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/register",
    name: "register",
    component: require("../views/authentication/RegisterUser.vue").default,
  },
  {
    path: "/verify",
    name: "verify",
    component: require("../views/authentication/VerifyUser.vue").default,
  },
  {
    path: "/logout",
    name: "Logout",
    component: require("../views/authentication/LogoutUser.vue").default,
  },
  {
    path: "/portal/home",
    name: "PortalHome",
    component: PortalHome,
    props: true,
  },
  {
    path: "/news",
    name: "news",
    component: require("../views/news/news").default,
    beforeEnter: authGuard,
    meta: {
      sideBar: true,
      requiresAuth: true,
    },
  },
  {
    path: "/schedules",
    name: "schedules",
    component: require("../views/schedules/schedules").default,
    beforeEnter: authGuard,
    meta: {
      sideBar: true,
      requiresAuth: true,
    },
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: require("../views/Notifications").default,
    beforeEnter: authGuard,
    meta: {
      sideBar: true,
      requiresAuth: true,
    },
  },
  {
    path: "/edit/video/:id",
    name: "editVideo",
    component: require("../views/UploadVideo").default,
    beforeEnter: authGuard,
    meta: {
      sideBar: true,
      requiresAuth: true,
    },
  },
  {
    path: "/embed/:id",
    name: "Embed",
    component: require("../views/embed").default,
    meta: {
      sideBar: false,
      requiresAuth: false,
      blankPage: true,
    },
  },
  // {
  //   path: "/video/:id",
  //   name: "viewVideo",
  //   component: require("../views/videoPlayerModal.vue").default,
  //   meta: {
  //     sideBar: false,
  //     requiresAuth: false,
  //     blankPage: true,
  //   },
  // },
  {
    path: "/video",
    name: "Video",
    component: require("../components/videoPlayerModal.vue").default,
    meta: {
      sideBar: false,
      requiresAuth: false,
      blankPage: true,
    },
  },

  {
    path: "/video/:id",
    name: "viewVideo",
    component: require("../components/videoPlayerModal.vue").default,
    meta: {
      sideBar: false,
      requiresAuth: false,
      blankPage: true,
    },
  },

];

const router = createRouter({
  history: createWebHistory(process.env.NODE_ENV.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});





router.beforeEach(async (to, from, next) => {
  try {
    if(auth.currentUser != null || auth.currentUser != undefined) {
      const userRef = db.collection('users').doc(auth.currentUser.uid);
      const userDoc = await userRef.get();
      if (userDoc.exists) {
        const userType = userDoc.data().account_type;
  
        if (userType === 3 && to.path === "/upload") {
          next("/feed");
        } else {
          next();
        }
      } else {
        console.log('User document does not exist');
        next();
      }
    } else {
      next();
    }
   
  } catch (error) {
    console.error('Error fetching user:', error);
    next();
  }
});






// >>> Old method for route guard
// router.beforeEach(async(to, from, next) => {
//     const user = auth.currentUser
//     console.log("!!!")
//     if (!user && to.path !== '/') {
//         return next('/')
//     }
//     next()
// })

export default router;
