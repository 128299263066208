import { reactive, computed } from "vue";

export const trims = reactive({
  startTime: 0,
  endTime: null,
});

export const duration = computed(() =>
  Math.abs(trims.endTime - trims.startTime)
);

export const resetTrims = () => {
  Object.assign(trims, {
    startTime: 0,
    endTime: null,
  });
};

export const thumbnailInfo = reactive({
  src: ''
})