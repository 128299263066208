<template>
    <div class="d-flex flex-wrap mx-auto my-6 " style="max-width: 900px">
      <v-app-bar app v-if="isMobile" class="centered-app-bar">
        <v-app-bar-nav-icon @click="toggleDrawer" />
        <img src="/assets/images/rodeo-logo.png"  height="50" style="object-fit: cover" class="image-cover" />
        <RotateIcon :onRotate="onLoad" />
      </v-app-bar>
  
      <div class="d-flex fixed-top" v-else style="width: 100%; padding: 0 0">
        <RotateIcon :onRotate="onLoad" />
      </div>
      <div class="video">
        <template v-for="(video, index) in videos" :key="video.firestoreID">
          <VideoVue @deleted="initialSetup((append = false))" style="width: 100%" :video="video" class="video-item" />
          <v-divider v-if="index !== videos.length - 1 && !loading" style="margin: 40px 0"></v-divider>
        </template>
  
        <div class="d-flex justify-center" style="width: 100%">
          <v-progress-circular class="mx-auto" indeterminate v-if="loading"></v-progress-circular>
        </div>
      </div>
      <v-card v-intersect="debouncedSetup" style="width: 100%"></v-card>
    </div>
  </template>
  
  <script setup>
  import store from "@/store";
  import { computed, watch, ref, onUnmounted, onBeforeMount, onMounted } from "vue";
  import {
    getFirestore,
    query,
    collection,
    where,
    orderBy,
    limit,
    startAfter,
  } from "firebase/firestore";
  import VideoVue from "@/components/utilities/Video.vue";
  import RotateIcon from "../RotateIcon.vue";
  
  import { useRoute } from 'vue-router';
  
  const route = useRoute();
  
  const isTopRides = ref(route.name === 'top-rides');
  
  let loading = ref(true);
  
  let videos = computed({
    get: () => {
      return store.state.videos || [];
    },
  });
  
  
  function onLoad() {
    store.commit('SET_VIDEOS', []);
    initialSetup();
  
  }
  
  function initialSetup(append = true) {
    if (!store.state.selectedProfile) return;
    loading.value = true;
    setTimeout(() => {
      loading.value = false;
    }, 30000);
    const db = getFirestore();
    let newVideos = store.state.videos;
    let ref;
  
    if (newVideos.length == 0) {
        ref = query(
          collection(db, "videos"),
          orderBy("created", "desc"),
          where("ride_of_the_week", "==", true),
          limit(6)
        );
    } else {
      ref = query(
        collection(db, "videos"),
        orderBy("created", "desc"),
        startAfter(videos.value[videos.value.length - 1].preserved),
        where("ride_of_the_week", "==", true),
        limit(6)
      );
    }
  
    store.dispatch("bindCollectionRef", {
      key: "videos",
      ref,
      append,
      preserve: true,
      callback: (docs) => docs,
    });
  
    loading.value = false;
  }
  
  
  
  
  function debouncedSetup() {
    setTimeout(() => {
    if (!loading.value && window.scrollY > 0) initialSetup(true);
  }, 500)
  }
  
  watch(
    () => store.state.selectedProfile,
    () => {
      initialSetup(false);
    }
  );
  
  onUnmounted(() => {
    store.commit("SET_VIDEOS", []);
  });
  
  onBeforeMount(() => {
    store.commit('SET_VIDEOS', []);
    initialSetup(false);
  });
  
  
  
  
  </script>
  
  <script>
  
  
  export default {
  
  
  
    data() {
      return {
        isMobile: false,
      };
    },
    methods: {
      toggleDrawer() {
        store.dispatch('toggleDrawer');
        console.log(store.state.drawer)
      },
    },
    mounted() {
  
      this.isMobile = window.innerWidth <= 1280;
      window.addEventListener('resize', () => {
        this.isMobile = window.innerWidth <= 1280;
      });
    },
  };
  </script>
  
  
  <style>
  .video {
    width: 100%
  }
  
  @media (max-width: 1280px) {
    .fixed-top {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 999;
    }
  
    .video {
      width: 90%;
      height: auto;
      align-items: center;
      margin: 0 auto;
    }
  
    .centered-app-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .image-cover {
    display: flex;
    align-items: center;
  }
  
  }
  </style>
  