import { reactive, computed } from "vue";
import { animal } from "./animal";
import { handlers } from "./handlers";
import events from "@/utils/events";
import dayjs from "dayjs";
import store from "@/store/index.js";

export const form = reactive({
  video_id: null,
  contestants_id: null,
  contestants_names:null,
  contractor_id: null,
  created: null,
  duration: null,
  score: null,
  time:null,
  event_date: "",
  title: "",
  user_name: null,
  notes: "",
  location: "",
  edited: false,
  user_id: null,
  thumbnail_url: "",
  animal_name:"",
  animal_brand:"",
  contractor_name:""
});

export const formData = computed(() => {

  const { created, time, score, event_date, contestants_id, contestants_names, ...rest } = form;
  const { scoreTime, selectedEvent } = handlers;
  const {
    brand: animal_brand,
    id: animal_id,
    name: animal_name,
    tagged_animal_contractor_id,
  } = animal;

  return {
    ...rest,
    created: dayjs().unix(),
    event_date: dayjs(event_date).unix(),
    event_type: events.indexOf(selectedEvent) + 1,
    score: scoreTime === "score" ? score : null,
    time: scoreTime === "time" ? time : null,
    animal_brand,
    animal_name,
    animal_id,
    tagged_animal_contractor_id,
    contestants_id,
    contestants_names,
  };
});

export const setContestant = (contestants = []) => {
  const { selectedContestant } = handlers;
  const record = contestants.find(
    (contestant) => contestant.title == selectedContestant
  );
console.log(record)
  if (record) {
    const { id: contestants_id, title: contestants_names } = record;

    Object.assign(form, {
      contestants_id,
      contestants_names,
    });
  }
};


// const d = {
//   contestants_invited_ids: video.contestantsInvitedIds,
//   user_name: video.userName,
//   contractor_id: video.contractorId,
//   account_upload: video.accountUpload,
//   contestants_names: video.contestantsNames,
//   contractor_name: video.contractorName,
//   ride_of_the_week: video.rideOfTheWeek,
// };
