import { createStore } from "vuex";

import { SpinnerGetters } from "./spinner/getters";
import { SpinnerMutations } from "./spinner/mutations";
import { SpinnerActions } from "./spinner/actions";

import { AlertGetters } from "./alert/getters";
import { AlertMutations } from "./alert/mutations";
import { AlertActions } from "./alert/actions";
import { NewsGetters } from "./news/getters";
import { NewsMutations } from "./news/mutations";
import { NewsActions } from "./news/actions";
import { SchedulesGetters } from "./schedules/getters";
import { SchedulesMutations } from "./schedules/mutations";
import { SchedulesActions } from "./schedules/actions";
import { onSnapshot } from "firebase/firestore";


const store = createStore({
  state: {
    showSpinner: false,
    trimDuration: null,
    showAlert: false,
    alertText: "",
    alertType: "",
    user: null,
    news: [],
    schedules: [],
    userProfile: null,
    selectedProfile: null,
    accessibleProfiles: [],
    subscribers: {},
    switchUserModal: false,
    modalVideo: null,
    videoToUpload: null,
    videoHeight: null,
    videoWidth: null,
    videoToTrimmed:null,
    videoToThumbnail:null,
    trimmedVideoName:null,
    videoPlayerModal: false,
    changeAccTypeModel: false,
    logOutModel: false,
    videos: [],
    animal: null,
    scrollY: 0,
    idUserProfile: null,
    originalDuration:0,
    videoToEdit: null,
    animalSelected: null,
    isEditVideo: false,
    isFromUpload: false,
    animalFromCreate: null,
    openDrawer: false,
    selectedTab: "Feed",
    searchKeyword: null,
    selectedAccountType: null,
  },
  getters: {
    ...SpinnerGetters,
    ...AlertGetters,
    ...NewsGetters,
    ...SchedulesGetters,
  },
  mutations: {
    ...SpinnerMutations,
    ...AlertMutations,
    ...NewsMutations,
    ...SchedulesMutations,
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    },
    SET_ACCOUNT_TYPE(state, type) {
      state.selectedAccountType = type;
    },
    SET_SEARCH_KEYWORD(state, keyword) {
      state.searchKeyword = keyword;
    },
    SET_TAB(state, tab) {
        state.selectedTab = tab;
    },
    SET_DRAWER(state, drawer) {
      state.drawer = drawer;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_ANIMAL_FROM_UPLOAD(state, isFromUpload) {
      state.isFromUpload = isFromUpload;
    },
    SET_ANIMAL_FROM_CREATE(state, animalFromCreate) {
      state.animalFromCreate = animalFromCreate;
    },
    SET_IS_VIDEO_EDIT(state, isEditVideo) {
      state.isEditVideo = isEditVideo;
    },
    
    SET_VIDEO_TO_EDIT(state, video) {
      state.videoToEdit = video;
    },
    SET_ANIMAL_TO_UPLOAD(state, animalSelected) {
      state.animalSelected = animalSelected;
    },
    SET_TRIM_DURATION(state, duration) {
      state.duration = duration;
    },
    SET_ANIMAL(state, animal) {
      state.animal = animal
    },
    SET_PROFILE(state, profile) {
      state.userProfile = profile;
    },
    SET_VIDEOS(state, videos) {
      state.videos = videos;
    },
    SET_SELECTED_PROFILE(state, profile) {
      state.selectedProfile = profile;
    },
    SET_ACCESSIBLE_PROFILES(state, profiles) {
      state.accessibleProfiles = profiles.filter((x) => x);
    },
    SET_SUBSCRIBER(state, { key, subscriber }) {
      try {
        state.subscribers[key]();
      } catch (error) {}
      state.subscribers[key] = subscriber;
    },
    SET_FIRESTORE_VALUE(state, { key, doc, append }) {
      if (append) {
        state[key] = [...state[key], ...doc];
      } else state[key] = doc;
    },
    VIDEO_PLAYER_MODAL(state, value) {
      state.videoPlayerModal = value;
    },
    LOGOUT_MODAL(state, value) {
      state.logOutModel = value;
    },
    SET_MODAL_VIDEO(state, video) {
      state.modalVideo = video;
    },
    SWITCH_USER_MODAL(state, value) {
      state.switchUserModal = value;
    },
    SCROLLY(state, value) {
      state.scrollY = value;
    },
    CHANGE_ACC_TYPE_MODAL(state, value) {
      state.changeAccTypeModel = value;
    },
    VIDEO_TO_UPLOAD(state, video) {
      state.videoToUpload = video;
    },
    VIDEO_TO_TRIMMED(state, video) {
      state.videoToTrimmed = video;
    },
    VIDEO_TO_THUMBNAIL(state, video) {
      state.videoToThumbnail = video;
    },
    VIDEO_FILENAME(state, name) {
      state.trimmedVideoName = name;
    },
    SET_DURATION(state, duration){
      state.originalDuration = duration;
    },
    search_(state, value) {
      state.search_ = value;
    },

    RESET_STATE(state) {
      state.showSpinner = false;
      state.trimDuration = null;
      state.showAlert = false;
      state.alertText = "";
      state.alertType = "";
      state.user = null;
      state.news = [];
      state.schedules = [];
      state.userProfile = null;
      state.selectedProfile = null;
      state.accessibleProfiles = [];
      state.subscribers = {};
      state.switchUserModal = false;
      state.modalVideo = null;
      state.videoToUpload = null;
      state.videoHeight = null;
      state.videoWidth = null;
      state.videoToTrimmed = null;
      state.videoToThumbnail = null;
      state.trimmedVideoName = null;
      state.videoPlayerModal = false;
      state.changeAccTypeModel = false;
      state.logOutModel = false;
      state.videos = [];
      state.animal = null;
      state.scrollY = 0;
      state.idUserProfile = null;
      state.originalDuration = 0;
      state.videoToEdit = null;
      state.animalSelected = null;
      state.isEditVideo = false;
      state.isFromUpload = false;
      state.animalFromCreate = null;
      state.selectedTab = 'Feed';
      state.searchKeyword = null;
      state.selectedAccountType = null;
    }
  },
  actions: {
    ...SpinnerActions,
    ...AlertActions,
    ...NewsActions,
    ...SchedulesActions,
    toggleDrawer({ commit }) {
      commit('toggleDrawer');
    },
    bindRef({ commit }, { key, ref, callback }) {
      let subscriber = onSnapshot(ref, async (snapDoc) => {
        let doc = {
          ...snapDoc.data(),
          id: snapDoc.id,
        };
        if (callback) doc = await callback(doc);
        commit("SET_FIRESTORE_VALUE", { key, doc });
      });
      commit("SET_SUBSCRIBER", { key, subscriber });
    },
    bindCollectionRef({ commit }, { key, ref, callback, append, preserve }) {
      console.log("<key>:", key);
      let subscriber = onSnapshot(ref, async (snapDocs) => {
        let docs = snapDocs.docs.map((snapDoc) => ({
          ...snapDoc.data(),
          id: snapDoc.id,
          preserved: preserve ? snapDoc : null,
        }));
        if (callback) docs = await callback(docs);
        commit("SET_FIRESTORE_VALUE", { key, doc: docs, append });
      });
      commit("SET_SUBSCRIBER", { key, subscriber });
    },
    clearSubscribers({ commit, state }) {
      Object.keys(state.subscribers).forEach((key) => {
        try {
          state.subscribers[key]();
        } catch (error) {}
        commit("SET_SUBSCRIBER", { key, subscriber: null });
        commit("SET_FIRESTORE_VALUE", { key, doc: null });
      });
      commit("SET_ACCESSIBLE_PROFILES", []);
    },
  },
});

export default store;
