<template>
    <v-btn
      @click="rotateIcon"
      icon
      variant="flat"
      style="margin-left: auto"
      :class="{ 'rotate-icon': isRotating }"
    >
    <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
          style="width: 32px"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
          />
        </svg>
    </v-btn>
  </template>
  
  <script setup>
  import { ref, defineProps } from 'vue';
  
  const isRotating = ref(false);

  const props = defineProps({
  onRotate: Function,
});
  
  const rotateIcon = () => {
    isRotating.value = true;
    props.onRotate()
    setTimeout(() => {
      isRotating.value = false;
    }, 500);
  };
  </script>
  
<style>
.rotate-icon {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>