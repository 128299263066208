<script setup>
import { ref, computed, watch, onBeforeMount } from "vue";
import {
  getFirestore,
  collection,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import VideosCursorPagination from "@/components/utilities/videosCursorPagination.vue";
import store from "@/store";
import AnimalCard from "@/components/utilities/animalCard.vue";
import { useSelectedAnimal } from "@/store/myRodeo/selected_animal";
import { useRoute, useRouter } from "vue-router";
import { getProfileImageById } from "@/services/profiles";
import { useStepState } from "@/store/uploadVideo/step.js";
import { getAuth } from "firebase/auth";
import mixpanel from "mixpanel-browser";
import { EngamentEvent } from "@/utils/event_enum";

const auth = getAuth();

const router = useRouter();

const currentUser = computed(() => {
  return auth.currentUser;
});


const { selected: select_animal } = useSelectedAnimal();

const db = getFirestore();
const route = useRoute();

const loading = ref(true);
const search = ref("");




const plusDropdown = [
  {
    title: "Upload Video",
    href: "/upload",
  },
  {
    title: "Add Animal",
    href: "/animals/new",
  },
  {
    title: "Add Social Media Link",
    href: "/profile/edit",
  },
];

const socialMediaLinks = computed(() => [
  {
    href: `https://www.facebook.com/${idUserProfile.value.facebook_url}`,
    icon: require("@/assets/icons/social-media/facebook.svg"),
  },
  {
    href: `https://www.instagram.com/${idUserProfile.value.instagram_url}/`,
    icon: require("@/assets/icons/social-media/instagram.svg"),
  },
  {
    href: `https://twitter.com/${idUserProfile.value.twitter_url}`,
    icon: require("@/assets/icons/social-media/twitter.svg"),
  },
  {
    href: `https://www.youtube.com/@${idUserProfile.value.youtube_url}`,
    icon: require("@/assets/icons/social-media/youtube.svg"),
  },
]);

const stepState = useStepState();
const videoInput = ref(null);
const videoInputForm = ref(null);


function uploadAVideo() {
  videoInputForm.value.reset();
  videoInput.value.click();
  router.push("/upload");
  stepState.step.value = 0;

}

function videoInputChange(event) {
  event.preventDefault();
  store.commit("VIDEO_TO_UPLOAD", event.target.files[0]);
  router.push("/upload");
}

const compareWithSearch = (value) =>
  value.toLowerCase().includes(search.value.toLowerCase());

const videos = computed(() => {
  let localVideos = store.state.videos;

  if (!localVideos) return [];

  if (search.value) {
    localVideos = localVideos.filter(
      ({ title, location, animal_brand, animal_name }) =>
        compareWithSearch(title ?? "") ||
        compareWithSearch(location ?? "") ||
        compareWithSearch(animal_brand ?? "") ||
        compareWithSearch(animal_name ?? "")
    );
  }

  localVideos.sort((a, b) => {
    return b.event_date - a.event_date;
  });

  return localVideos;
});

const userProfile = computed(() => {
  return store.state.userProfile;
});

// watch(userProfile, (v) => {
//   if (v) initialSetup();
// });

const showVideo = ref(false);

const animals = computed(() => {
  let localAnimals = store.state.animals;

  if (search.value) {
    localAnimals = localAnimals.filter(
      ({ name, brand }) => compareWithSearch(name) || compareWithSearch(brand)
    );
  }
  localAnimals.sort((a, b) => {
    if (a.name === "" && b.name === "") {
      return 0;
    } else if (a.name === "") {
      return 1;
    } else if (b.name === "") {
      return -1;
    } else {
      return a.name.localeCompare(b.name);
    }
  });
  return localAnimals;
});

const coverPhoto = computed(() => {
  if (!idUserProfile.value) return;
  return idUserProfile.value.account_type !== 2
    ? idUserProfile.value.account_type !== 3
      ? require("@/assets/images/contractors.jpg")
      : require("@/assets/images/bareback.jpg")
    : require("@/assets/images/contestants.jpg");
});

const filteredAnimals = computed(() => {
  let localAnimals = animals.value;

  if (select_animal.value == "bulls") {
    localAnimals = localAnimals.filter((animal) => animal.animal_type == 1);
  } else if (select_animal.value == "horses") {
    localAnimals = localAnimals.filter((animal) => animal.animal_type == 2);
  }

  return localAnimals;
});

const idUserProfile = ref(null);

function getAccountType(type) {
  if(type == 1) {
    return 'Contractor Profile';
  }
  if(type == 2) {
    return 'Contestant Profile';
  }

  return 'Fan Profile';
}

async function initialSetup() {
  loading.value = true;
  // store.commit("SET_TAB", getAccountType(idUserProfile.value.account_type))
  let paramUserId = (new URLSearchParams(window.location.search)).get("userId")
  let pathUserId = route.path.split("/")[route.path.split("/").length - 1]
  let profileId
  if (pathUserId != "my-rodeo") {
    profileId = pathUserId ?? currentUser.value.uid;
  } else {
    profileId = paramUserId ?? currentUser.value.uid;
  }

  try {
    store.commit("SET_FIRESTORE_VALUE", { key: "animals", doc: [] });
    store.state.subscribers["animals"]();
  } catch { 
    console.log("Error")
  }
  try {
    store.commit("SET_FIRESTORE_VALUE", { key: "videos", doc: [] });
    store.state.subscribers["videos"]();
  } catch { 
    console.log("Error")
  }
  console.log(profileId)
  getDoc(doc(db, "users", profileId))
    .then(async (doc) => {
      idUserProfile.value = {
        ...doc.data(),
        id: doc.id,
        photo_url: await getProfileImageById({
          id: doc.id,
          account_type: doc.data().account_type,
        }),
      };

      var name = idUserProfile.value.first_name + idUserProfile.value.last_name;
      if (idUserProfile.value.id != store.state.userProfile.id) {
        mixpanel.track(EngamentEvent.viewed, {
          "name": name,
          "type": getAccountType(idUserProfile.value.account_type)
        })
      }
      let docRef;
      let key =
        idUserProfile.value.account_type == 2 ? "user_id" : "contractor_id";



      docRef = query(
        collection(db, "videos"),
        where(key, "==", idUserProfile.value.id)
      );


      store.dispatch("bindCollectionRef", { key: "videos", ref: docRef });
      if (currentUser.value == null) {
        store.commit("SET_PROFILE", doc.data() );
      }

      if (
        idUserProfile.value.account_type == 1 ||
        idUserProfile.value.account_type == 2
      ) {
        docRef = query(
          collection(db, "animals"),
          where("contractor", "==", idUserProfile.value.id)
        );

        store.dispatch("bindCollectionRef", { key: "animals", ref: docRef });
      }
    })
    .finally(() => {
      console.log("Loaded")
      setTimeout(() => {
        loading.value = false;
      }, 1000);
    });
}

onBeforeMount(() => {
  initialSetup();
});
</script>

<script>
export default {
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    toggleDrawer() {
      store.dispatch('toggleDrawer');
    },
  },
  mounted() {

    this.isMobile = window.innerWidth <= 1280;
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth <= 1280;
    });
  },
}
</script>

<template>
  <v-app-bar app v-if="isMobile" class="centered-app-bar">
    <v-app-bar-nav-icon @click="toggleDrawer" />
    <v-app-bar-nav-icon v-html="'My Rodeo'" style="margin-left: 30px;" />
  </v-app-bar>
  <template v-if="loading">
    <div class="d-flex flex-column">
      <v-skeleton-loader type="image"></v-skeleton-loader>
      <div style="position: relative; bottom: 60px; width: 100%" class="d-flex flex-column align-center mx-auto">
        <v-skeleton-loader width="180" type="image, list-item-two-line">
        </v-skeleton-loader>
      </div>
      <div style="width: 100%; max-width: 900px" class="mx-auto my-6">
        <v-skeleton-loader type="sentences"></v-skeleton-loader>
      </div>
      <div style="width: 100%; max-width: 900px" class="mx-auto my-6">
        <v-skeleton-loader type="table-row-divider"></v-skeleton-loader>
        <v-skeleton-loader type="table-row-divider"></v-skeleton-loader>
        <v-skeleton-loader type="table-row-divider"></v-skeleton-loader>
        <v-skeleton-loader type="table-row-divider"></v-skeleton-loader>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="d-flex flex-column">
      <img cover class="d-flex align-start" :src="coverPhoto" style="
      height: 250px;
      width: auto;
      object-fit: cover;
      " />

      <div style="position: relative; width: 100%; max-width: 900px"
        class="d-flex flex-column align-center mx-auto">
        <div style="width: 100%" class="d-flex justify-space-between pa-3">
          <div style="position: absolute; top: 12px; left: 0">
            <v-btn v-if="((userProfile != null && idUserProfile.id != userProfile.id) || $store.state.selectedProfile == null)" icon size="small" variant="plain" class="d-flex align-center justify-center" @click="$router.go(-1)">
              <img style="height: 30px" :src="require('@/assets/icons/glyph/glyphs/chevron.left.png')" />
            </v-btn>
          </div>

          <v-spacer />
          <v-avatar color="transparent" size="180" style="
              border-radius: 5%;
              position: relative;
              bottom: 90px;
              margin-bottom: -100px;
            " tile>
            <img crossorigin class="avatar" cover :aspect-ratio="1" :src="idUserProfile.photo_url" />
          </v-avatar>
          <v-spacer style="margin-bottom: 10px;" />
          <div v-if="!isMobile" class="d-flex" style="position: absolute; top: 12px; right: 0; column-gap: 8px">
            <template v-for="link in socialMediaLinks" :key="link">
              <a target="_blank" :href="link.href" class="social-media-link">
                <img :src="link.icon" />
              </a>
            </template>
          </div>
        </div>



        <div class="d-flex flex-column text-center">

          <div class="d-flex align-center">

            <h3 class="h4">
              {{ idUserProfile.first_name }} {{ idUserProfile.last_name }}
            </h3>
            <v-icon :class="idUserProfile.isVerified
              ? 'text-primary'
              : 'text-grey-lighten-1'
              " class="ml-2" size="14" icon="fas fa-check-circle" />
          </div>
          <span class="text--disabled">{{ idUserProfile.location }}</span>
          
        </div>
        <div v-if="isMobile" class="d-flex" style="bottom: 20px; column-gap: 8px">
        <template v-for="link in socialMediaLinks" :key="link">
          <a target="_blank" :href="link.href" class="social-media-link">
            <img :src="link.icon" />
          </a>
        </template>
      </div>
      </div>

    

      <div v-if="idUserProfile" style="width: 100%; max-width: 900px" class="mx-auto mt-6 d-flex flex-column">
        <span class="subtitle-1 font-italic text-gray text--disabled">
          {{ idUserProfile.bio }}
        </span>
        <span class="mt-6 subtitle-1 text-gray text--disabled">
          Sponsors: {{ idUserProfile.sponsors }}
        </span>
      </div>

      <template v-if="(userProfile != null && idUserProfile.id == userProfile.id && idUserProfile.account_type != 3) && currentUser !=null">
        <div class="d-flex justify-center mt-6">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn icon size="small" variant="plain" class="d-flex items-center justify-center" v-bind="props">
                <img :src="require('@/assets/icons/glyph/glyphs/plus.circle.red.png')
                  " />
              </v-btn>
            </template>

            <v-list>
              <template v-for="(item, index) in plusDropdown" :key="index">
                <!-- Check if item.href is equal to '/upload' -->
                <template v-if="item.href === '/upload'">
                  <v-btn block @click="uploadAVideo" class="text-black" variant="text">
                    {{ item.title }}
                  </v-btn>
                  <v-divider></v-divider>
                </template>
                <!-- Check if item.href is not equal to '/upload' -->
                <template v-else>
                  <v-btn block variant="text" class="text-black" :disabled="!item.href"
                    @click="$router.push(item.href)">{{
                      item.title }}</v-btn>
                  <v-divider v-if="index !== plusDropdown.length - 1"></v-divider>
                </template>
              </template>
            </v-list>
          </v-menu>
        </div>
      </template>

      <template v-if="idUserProfile && idUserProfile.account_type != 3">
        <div style="width: 100%; max-width: 900px" class="d-flex flex-column mx-auto mt-6">
          <h3 class="h5 mb-2">{{ !showVideo ? "Animals" : "Videos" }}</h3>

          <v-text-field style="margin: 10px" v-if="isMobile" v-model="search" density="compact"
            prepend-inner-icon="fas fa-search" color="white" hide-no-data hide-selected hide-details variant="outlined"
            :placeholder="!showVideo
              ? 'Start typing to Search Animals'
              : 'Start typing to Search Videos'
              " return-object class="py-0" :class="{ 'mr-3': !showVideo }">
          </v-text-field>
          <div class="d-flex align-center mb-6">
            <template v-if="!showVideo">
              <v-btn @click="showVideo = !showVideo" icon variant="plain" class="d-flex items-center justify-center mr-3">
                <img style="width: 30px" :src="require('@/assets/icons/glyph/glyphs/rectangle.grid.2x2.png')
                  " />
              </v-btn>
            </template>
            <template v-else>
              <v-btn v-if="idUserProfile.account_type == 1 ||
                idUserProfile.account_type == 2
                " @click="showVideo = !showVideo" icon variant="plain" class="d-flex items-center justify-center mr-3">
                <img style="width: 30px" :src="require('@/assets/icons/glyph/glyphs/list.dash.png')" />
              </v-btn>
            </template>
            <v-text-field v-if="!isMobile" v-model="search" density="compact" prepend-inner-icon="fas fa-search"
              color="white" hide-no-data hide-selected hide-details variant="outlined" :placeholder="!showVideo
                ? 'Start typing to Search Animals'
                : 'Start typing to Search Videos'
                " return-object class="py-0" :class="{ 'mr-3': !showVideo }"></v-text-field>
            <template v-if="!showVideo">
              <div class="ml-auto d-flex align-center">
                <v-btn @click="$router.push({ path: '/animals/new' })" v-if="idUserProfile.account_type == 1 ||
                  idUserProfile.account_type == 2
                  " icon size="small" variant="text" class="d-flex items-center justify-center mr-2">
                  <img style="width: 30px" :src="require('@/assets/icons/glyph/glyphs/plus.circle.png')
                    " />
                </v-btn>

                <v-btn-toggle v-if="animals" class="ml-1">
                  <v-btn size="small" :active="select_animal == 'all'" @click="select_animal = 'all'">
                    All ({{ animals.length }})
                  </v-btn>

                  <v-btn size="small" :active="select_animal == 'bulls'" @click="select_animal = 'bulls'">
                    Bulls ({{
                      animals.filter((animal) => animal.animal_type == 1)
                        .length
                    }})
                  </v-btn>

                  <v-btn size="small" :active="select_animal == 'horses'" @click="select_animal = 'horses'">
                    Horses ({{
                      animals.filter((animal) => animal.animal_type == 2)
                        .length
                    }})
                  </v-btn>
                </v-btn-toggle>
              </div>

            </template>
          </div>

          <template v-if="!showVideo">
            <template v-if="filteredAnimals.length">
              <template v-for="animal in filteredAnimals" :key="animal.id">
                <AnimalCard :animal="animal" :videos="videos" />
              </template>
            </template>
            <span v-if="!animals || animals.length == 0" class="font-italic">No animals to show</span>
          </template>
          <template v-else>
            <template v-if="videos.length">
              <VideosCursorPagination :videos="videos" :key="videos.length" />
            </template>
            <span v-if="!videos || videos.length == 0" class="font-italic">No videos to show</span>
          </template>
        </div>
      </template>
    </div>
  </template>
  <form ref="videoInputForm" @submit.prevent>
    <input @change="videoInputChange" type="file" accept="video/mp4,video/x-m4v,video/quicktime,video/*"
      style="display: none" ref="videoInput" />
  </form>
</template>

<style>
.social-media-link {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  padding: 0.5rem;
  border: 1px solid gray;
  background-color: white;
  width: 40px;
  height: 40px;
  transition: all ease-in 150ms;
}

.social-media-link:hover {
  border-color: rgb(var(--v-theme-primary)) !important;
}

.social-media-link img {
  width: 28px;
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5%;
  position: relative;
  bottom: 90px;
  margin-bottom: -180px;
}


@media (max-width: 1280px) {
  .social-media-link img {
    width: 14px;
  }

  .avatar {
    width: 60%;
    height: 60%;
    object-fit: cover;
    border-radius: 5%;
    position: relative;
    bottom: 90px;
    margin-bottom: -180px;
  }

  .social-media-link {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    padding: 0.5rem;
    border: 1px solid gray;
    background-color: white;
    width: 30px;
    height: 30px;
    transition: all ease-in 150ms;
  }
}
</style>
