<template>
  <div
    class="mb-4"
    style="width: 100%; height: 500px; background: black; border-radius: 8px"
  >
    <div
      style="
        display: flex;
        justify-content: center;
        height: 100%;
        position: relative;
      "
    >
      <div
        class="animate-pulse"
        style="
          background: rgb(41, 37, 36);
          width: 350px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      />
    </div>
  </div>
  <div
    style="
      width: 100%;
      height: 54px;
      position: relative;
      border-radius: 8px;
      background: rgb(214, 211, 209);
    "
  >
    <div class="animate-pulse">
      <div
        style="
          width: 5px;
          height: 100%;
          background: rgb(168, 162, 158);
          position: absolute;
          left: 50%;
          right: 50%;
        "
      />
    </div>
  </div>
</template>
