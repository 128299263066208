<template>
  <div class="d-flex flex-column">
    <v-img cover height="250px" class="d-flex align-start" :src="coverPhoto">
    </v-img>

    <div
      v-if="animal"
      style="position: relative; width: 100%; max-width: 900px"
      class="d-flex flex-column align-center mx-auto"
    >
      <div
        style="width: 100%"
        
        class="d-flex justify-space-between pa-3"
      >
        <div style="position: absolute; top: 12px; left: 0">
          <v-btn            
            icon
            size="small"
            variant="plain"
            class="d-flex align-center justify-center"
            @click="onTapBack"
          >
            <img
              style="height: 30px"
              :src="require('@/assets/icons/glyph/glyphs/chevron.left.png')"
            />
          </v-btn>
        </div>

        <v-spacer />
       
        <v-avatar color="transparent" size="180" style="
              border-radius: 5%;
              position: relative;
              bottom: 90px;
              margin-bottom: -90px;
            " tile>
            <img crossorigin cover :aspect-ratio="1" style="
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 5%;
                position: relative;
                bottom: 90px;
                margin-bottom: -180px;
              " 
              :src="animalImage" />
          </v-avatar>
        <v-spacer />

        <template v-if=" $store.state.selectedProfile && animal.contractor === $store.state.selectedProfile.id">
          <div style="position: absolute; top: 12px; right: 0">
            <router-link
              :to="{
                path: `/animals/edit/${animal.id}`,
              }"
            >
              <v-btn dark color="error" variant="text" class="ml-1">edit</v-btn>
            </router-link>
          </div>
        </template>
      </div>

      <div class="d-flex flex-column text-center">
        <h3 class="">
          <span class="text--disabled">{{ animal.brand }}</span>
          {{ animal.name }}
        </h3>
        <span class="text--disabled">{{ animal.location }}</span>
        <button
          class="text-none"
          @click="onTapProfile"
         >
        <span class="text-primary">{{ animal.contractor_name }}</span>
        </button>
        <span class="text--disabled text-caption">{{
          parseEvents(animal.events)
        }}</span>
      </div>

      <div class="d-flex justify-center mt-6" v-if="currentUser!=null">
        <v-btn
          v-if="(animal && $store.state.selectedProfile && animal.contractor === $store.state.selectedProfile.id)"
          @click="uploadAVideo"
          icon
          size="small"
          variant="text"
          class="d-flex items-center justify-center mr-2"
        >
          <img
            :src="require('@/assets/icons/glyph/glyphs/plus.circle.red.png')"
          />
        </v-btn>
      </div>
    </div>

    <div
      style="width: 100%; max-width: 900px"
      class="mx-auto mb-6"
      v-if="showVideos"
    >
      <div style="width: 100%" class="d-flex align-center my-6">
        <v-text-field
          v-model="search"
          density="compact"
          prepend-inner-icon="fas fa-search"
          color="white"
          hide-no-data
          hide-selected
          hide-details
          variant="outlined"
          placeholder="Start typing to Search Videos"
          return-object
          class="py-0"
        ></v-text-field>
      </div>

      <template v-for="(video, index) in videos" :key="video.firestoreID">
        <VideoVue style="width: 100%" :video="video" />
        <v-divider
          v-if="index !== videos.length - 1"
          style="margin: 40px 0"
        ></v-divider>
      </template>
    </div>
  </div>
  <form ref="videoInputForm" @submit.prevent>
      <input
        @change="videoInputChange"
        type="file"
        accept="video/mp4,video/x-m4v,video/quicktime,video/*"
        style="display: none"
        ref="videoInput"
      />
  </form>
</template>

<script setup>
import { getAnimalImage } from "@/services/profiles.js";
import { onMounted, computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getAuth } from "firebase/auth";
import store from "@/store";
import {
  doc,
  getFirestore,
  query,
  collection,
  where,
} from "firebase/firestore";
import VideoVue from "@/components/utilities/Video.vue";
import events, { parseEvents } from "@/utils/events";
import { useStepState } from "@/store/uploadVideo/step.js";
import mixpanel from "mixpanel-browser";
import { EngamentEvent } from "@/utils/event_enum";

const auth = getAuth();

const router = useRouter();


let route = useRoute();
let db = getFirestore();
const userProfile = computed(() => {
  return store.state.userProfile;
});

const animalImage = ref(null);
const search = ref(null);
const showVideos = ref(false);
const stepState = useStepState();
const videoInput = ref(null);
const videoInputForm = ref(null);

const currentUser = computed(() => {
  return auth.currentUser;
});


function uploadAVideo() {
  videoInputForm.value.reset();
  videoInput.value.click();
  store.commit("SET_ANIMAL_TO_UPLOAD", animal.value);
  console.log("INIT",store.state.animalSelected);
  router.push("/upload");
  stepState.step.value = 0;
 
}

function videoInputChange(event) {
  event.preventDefault();
  store.commit("VIDEO_TO_UPLOAD", event.target.files[0]);
 
}

function onTapProfile() {
  console.log("Open profile")
  router.push(`/my-rodeo?userId=${animal.value.contractor}`)
}
function onTapBack() {
  if (router.options.history.state.back == "/animals/new" || router.options.history.state.back.includes('animals/edit')) {
    router.push("/my-rodeo")
  } else {
    router.go(-1)
  }
}

onMounted(() => {
  getAnimal();
});

async function getAnimal() {
  if (!route.params.id) {
    return;
  }
  try {
    store.commit("SET_FIRESTORE_VALUE", { key: "animal", doc: null });
    store.state.subscribers["animal"]();
  } catch {}

  let docRef = doc(db, "animals", route.params.id);
  store.dispatch("bindRef", { key: "animal", ref: docRef });
}

const animal = computed(() => store.state.animal);

watch(animal, (a) => {
  getVideos();
  if (a) getImage();
});

const coverPhoto = computed(() => {
  if (!animal.value) {
    return;
  }
  return animal.value.animal_type == 2
    ? require("@/assets/images/barrel-racing.jpg")
    : require("@/assets/images/bull-riding.jpg");
});

async function getImage() {
  animalImage.value = await getAnimalImage(animal.value);

  store.commit("SET_TAB", "Animal Profile");

  console.log(animal.value)

  mixpanel.track(EngamentEvent.viewed, {
    "type": "Animal Profile",
    "name": animal.value.brand + " " + animal.value.name,
  })
}

function getVideos() {
  if (!animal.value) {
    return;
  }
  try {
    store.commit("SET_FIRESTORE_VALUE", { key: "videos", doc: [] });
    store.state.subscribers["videos"]();
  } catch {}

  let ref = query(
    collection(db, "videos"),
    where("animal_id", "==", animal.value.id)
  );
  store.dispatch("bindCollectionRef", { key: "videos", ref });

  showVideos.value = true;
}

const compareWithSearch = (value) =>
  value.toLowerCase().includes(search.value.toLowerCase());

const videos = computed(() => {
  let localVideos = store.state.videos;

  if (!localVideos) return [];

  if (search.value) {
    localVideos = localVideos.filter(
      ({ title, location, animal_brand, animal_name }) =>
        compareWithSearch(title ?? "") ||
        compareWithSearch(location ?? "") ||
        compareWithSearch(animal_brand ?? "") ||
        compareWithSearch(animal_name ?? "")
    );
  }

  localVideos.sort((a, b) => {
    return b.event_date - a.event_date;
  });

  return localVideos || [];
});
</script>
