<script setup>
// [SFC]
import { ref, onMounted } from "vue";

// [Components]
import Form from "@/components/UploadVideo/Form.vue";
import VideoTrimmer from "@/components/UploadVideo/VideoTrimmer.vue";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { toBlobURL, fetchFile } from "@ffmpeg/util";
// [Utils]
import { v4 as uuidv4 } from "uuid";

// [Store & State]
import store from "@/store/index.js";
import { useAlertState } from "@/store/alert";
import { form, formData } from "@/store/uploadVideo/form.js";
import { duration, trims, thumbnailInfo } from "@/store/uploadVideo/trims.js";
import { useStepState } from "@/store/uploadVideo/step.js";
import { useEventState } from "@/store/event.js";
import { handlers } from "@/store/uploadVideo/handlers";
import { db, storage } from '../plugins/firebase'
import { useRouter } from "vue-router";
import { generateVideoThumbnailViaUrl } from '@rajesh896/video-thumbnails-generator'

import { onBeforeMount } from "vue";
import { addDoc, collection, updateDoc, query, where, getDocs, doc } from "firebase/firestore";
import mixpanel from 'mixpanel-browser';
import UploadVideoEvent from "@/utils/event_enum";
import events from "@/utils/events";

//ffpmeg Base Url
const FFPMEG_CDN_URL = 'https://unpkg.com/@ffmpeg/core-mt@0.12.2/dist/umd'
const SERVER_URL =
  process.env.NODE_ENV == "production"
    ? "https://server.rodeonow.com"
    : "https://testserver.rodeonow.com";

const upcomingEvent = useEventState();
const router = useRouter();

const alertState = useAlertState();
const { setAlert } = alertState;

const stepState = useStepState();
const { getStep } = stepState;

const maxDuration = 120;
const editOrUpdate = ref(false);
const valid = ref(false);
const uploading = ref(false);
const trimming = ref(false);
const uploadingProgress = ref(0);
const trimmingProgress = ref(0);
const ffmpeg = new FFmpeg();
const fileFormat = {
  'mov': "video/quicktime",
  'mp4': "video/mp4"
}
const showSaveVideoPopup = ref(false);

const loadffmpeg = async () => {

  ffmpeg.on("progress", ({ progress }) => {
    console.log(`Trimming progress ${progress * 100}%`)
    trimmingProgress.value = progress * 100;
  });
  await ffmpeg.load({
    coreURL: await toBlobURL(`/assets/ffmpeg/ffmpeg-core.js`, 'text/javascript'),
    wasmURL: await toBlobURL(`/assets/ffmpeg/ffmpeg-core.wasm`, 'application/wasm'),
    workerURL: await toBlobURL(`/assets/ffmpeg/ffmpeg-core.worker.js`, 'text/javascript')
  });
}

onBeforeMount(loadffmpeg);

const isFromUpload = store.state.isFromUpload ? 'From Picker' : 'From Edit';

async function trimVideo(inputVideoFile, ext, startTime, endTime) {
  console.log(`test: UploadVideo#trimVideo: ext: ${ext} startTime: ${startTime} endTime: ${endTime}`)
  const { name } = inputVideoFile;
  const output = `output.${ext}`

  try {
    console.log("Start trimming")
    const file = await writeFile(name, inputVideoFile)
    const exec = await ffmpeg.exec(['-ss', startTime.toString(), '-to', endTime.toString(), '-i', name, "-c", "copy", "-avoid_negative_ts", "make_zero", output]);
    const data = await ffmpeg.readFile(output);
    console.log('test: UploadVideo#trimVideo: exec: ', exec)
    await thumbnailVideo(inputVideoFile, startTime, ext);
    console.log(`Trimming finish with code ${exec}, output: ${name}`)
    await unLinkFile(name);
    await unLinkFile(output);
    const trimmedVideo = new Blob([data.buffer], { type: fileFormat[ext] })
    store.commit("VIDEO_TO_TRIMMED", trimmedVideo)
    store.commit("VIDEO_FILENAME", name);
  } catch (error) {
    console.log(`trimVideo: error: ${error}`)
  }
}

function base64ToBlob(base64) {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: 'image/jpeg' });
}

async function thumbnailVideo(inputVideoFile, time = 0, ext) {
  console.log('test: UploadVideo#thumbnailVideo: inputVideoFile: ', inputVideoFile)
  try {
    const reader = new FileReader();
    reader.onload = async function () {
      const videoBlob = new Blob([new Uint8Array(reader.result)], { type: fileFormat[ext] });
      const videoUrl = URL.createObjectURL(videoBlob);
      console.log('test: UploadVideo#thumbnailVideo: videoUrl: ', videoUrl)
      const base64ImageURL = await generateVideoThumbnailViaUrl(videoUrl, time)
      console.log('test: UploadVideo#thumbnailVideo: base64ImageURL: ', base64ImageURL)
      thumbnailInfo.src = base64ImageURL
      const base64ImageData = base64ImageURL.substring(base64ImageURL.indexOf(',') + 1);
      const blob = base64ToBlob(base64ImageData)
      store.commit('VIDEO_TO_THUMBNAIL', blob);
    };
    reader.readAsArrayBuffer(inputVideoFile)
  } catch (error) {
    console.log(`test: UploadVideo#error: ${error}`)
  }
}

async function unLinkFile(filePath) {
  return await ffmpeg.deleteFile(filePath)
}

async function writeFile(fileName, filePath) {
  return await ffmpeg.writeFile(fileName, await fetchFile(filePath));
}


const toNext = async () => {
  try {
    const inputVideoPath = store.state.videoToUpload;
    const { name: originalName } = inputVideoPath
    // Check if the originalName has an extension
    const nameParts = originalName.split('.');
    let ext = ''
    if (nameParts.length < 2) {
      setAlert(
        "error",
        "Invalid file name. Please ensure your file has an extension (e.g., .mp4, .mov)."
      );
    } else {
      ext = nameParts.pop()
    }
    if (Number(duration.value) > maxDuration) {
      setAlert(
        "error",
        `Videos must be ${maxDuration} seconds or less. Please trim the existing video or upload a new video.`
      );
    } else if (ext.toLowerCase() !== 'mp4' && ext.toLowerCase() !== 'mov') {
      setAlert(
        "error",
        "Invalid video format. Please upload a video in .mp4 or .mov format."
      );
    } else {
      if (!ffmpeg.loaded) {
        const loaded = await loadffmpeg()
      }
      trimming.value = true;
      const outputVideoData = await trimVideo(inputVideoPath, ext, trims.startTime, trims.endTime);
      store.commit("SET_TRIM_DURATION", trims.endTime - trims.startTime);
      (outputVideoData);
      stepState.step.value = 1;
    }
    trimming.value = false;
    if (ffmpeg.loaded) {
      ffmpeg.terminate()
    }
  } catch (e) {
    console.log(e);
    setAlert('error', 'This video had an error processing. Please try again, select a different video, or try on a different device.')
  }
};

const submitBtn = ref();

function setPresaved() {
  const { title: event, event_date: date, location } = form;
  const { selectedEvent: eventType } = handlers;

  Object.assign(upcomingEvent.value, {
    date,
    event,
    eventType,
    location,
  });
}

async function updateVideo() {
  uploading.value = true;
  editOrUpdate.value = true;
  const videoEditedData = formData.value;

  const videoIdToUpdate = store.state.videoToEdit.video_id;

  let event_date = videoEditedData.event_date

  if (typeof videoEditedData.event_date === "string") {
    event_date = new Date(event_date).getTime() / 1000;
  }

  const colRef = collection(db, 'videos');
  const queryRef = query(colRef, where('video_id', '==', videoIdToUpdate));


  const querySnapshot = await getDocs(queryRef);

  let time = videoEditedData.time != null ? parseFloat(videoEditedData.time) : null;
  let score = videoEditedData.score != null ? parseFloat(videoEditedData.score) : null;

  querySnapshot.forEach((docItem) => {
    const docRef = doc(db, 'videos', docItem.id);
    const newData = {
      notes: videoEditedData.notes,
      location: videoEditedData.location,
      title: videoEditedData.title,
      event_date: event_date,
      event_type: videoEditedData.event_type,
      time: time,
      score: score,
      edited: true,

    };

    if (videoEditedData.animal_name !== null) {
      newData.animal_name = videoEditedData.animal_name;
      newData.animal_id = videoEditedData.animal_id;
      newData.animal_brand = videoEditedData.animal_brand;
      newData.tagged_animal_contractor_id = videoEditedData.tagged_animal_contractor_id;
    }


    let contestants_id = videoEditedData.contestants_id != null || videoEditedData.contestants_id != undefined;

    (contestants_id);

    let contestants_names = videoEditedData.contestants_names != null || videoEditedData.contestants_names != undefined;
    if (contestants_id) {
      newData.contestants_id = [videoEditedData.contestants_id];
    }

    if (contestants_names) {
      newData.contestants_names = [videoEditedData.contestants_names];
    }

    (videoEditedData)

    console.log(newData)

    updateDoc(docRef, newData)
      .then(() => {
        (`Video_id updated is: ${videoIdToUpdate}`);
        store.commit("SET_VIDEO_TO_EDIT", null);
        store.commit("SET_ANIMAL", null);
        updateUserMap(videoEditedData, 1);
        router.push("/feed");
      })
      .catch((error) => {
        console.error('ERROR: ', error);
      });
  });
}

function updateUserMap(video, value) {
    if (localStorage) {
        let userMap = JSON.parse(localStorage.getItem('countUploadVideo')) || {};
        
        if (userMap.hasOwnProperty(video.userId)) {
            userMap[video.userId] += value;
        } else {
            userMap[video.userId] = value;
        }

        

        mixpanel.track(UploadVideoEvent.UPLOAD_SUCCESS, {
          "Account Type": store.state.userProfile.account_type == 1 ? "Contractor" : "Contestant",
          "Upload type": store.state.isEditVideo ? 'Edit Video' : 'Upload Recorded Video',
          "Rodeo Event": events[video.event_type - 1],
          "Location": video.location,
          "Event Date": video.event_date,
          "Event Type": video.event_type,
          "Animal": video.animal_brand + " " + video.animal_name,
          "Score": video.score,
          "Contestant": video.contestants_names,
          "Notes present": video.notes,
          "Number of uploads": userMap[video.userId],
        });

        localStorage.setItem('countUploadVideo', JSON.stringify(userMap));
    } else {
        console.error('LocalStorage is not supported in this browser.');
    }
}

async function createVideo() {

  (formData.value)
  uploading.value = true;

  const fileUUID = uuidv4();
  const thumbnailRef = storage.child(`videos/${fileUUID}/${fileUUID}.png`);
  const videoRef = storage.child(`videos/${fileUUID}.mov`);

  const thumbnailUploadTask = thumbnailRef.put(store.state.videoToThumbnail);
  const videoUploadTask = videoRef.put(store.state.videoToTrimmed);

  let thumbnailProgress = 0;
  let videoProgress = 0;

  thumbnailUploadTask.on("state_changed", (snapshot) => {
    thumbnailProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    updateOverallProgress();
  });

  videoUploadTask.on("state_changed", (snapshot) => {
    videoProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    updateOverallProgress();
  });

  const updateOverallProgress = () => {
    const overallProgress = (thumbnailProgress + videoProgress) / 2;
    uploadingProgress.value = overallProgress;
  };

  await Promise.all([thumbnailUploadTask, videoUploadTask]);

  const thumbnailURL = await thumbnailRef.getDownloadURL();
  const videoUrl = await videoRef.getDownloadURL();

  const createdDate = new Date(formData.value.created * 1000);
  let time;
  let score;

  if (formData.value.time == null && formData.value.score == null) {
    score = null;
    time = null;

  } else {
    if (formData.value.time != null) {
      score = null
      time = parseFloat(formData.value.time ?? 0)
    } else {
      time = null
      score = parseFloat(formData.value.score ?? 0)
    }
  }


  const userProfile = store.state.userProfile;
  const animal = store.state.animal;
  (animal);
  const formDataValue = formData.value;

  const formDataObject = {
    "user_id": userProfile.id,
    "account_upload": userProfile.account_type,
    "user_name": `${userProfile.first_name} ${userProfile.last_name}`,
    "location": formDataValue.location,
    "contestants_id": [formDataValue.contestants_id],
    "contestants_names": [formDataValue.contestants_names],
    "contractor_id": formDataValue.contractor_id,
    "created": createdDate,
    "event_date": formDataValue.event_date,
    "event_type": formDataValue.event_type,
    "notes": formDataValue.notes,
    "score": score,
    "thumbnail_url": thumbnailURL,
    "time": time,
    "title": formDataValue.title,
    "video_id": fileUUID,
    "duration": store.state.duration,
  };

  if (animal !== null) {
    Object.assign(formDataObject, {
      "animal_id": animal.id,
      "animal_brand": animal.brand,
      "animal_name": animal.name,
      "tagged_animal_contractor_id": formDataValue.tagged_animal_contractor_id,
      "contractor_name": animal.contractor_name,
    });
  }


  const colRef = collection(db, 'videos')
  const dataObj = formDataObject

  await addDoc(colRef, dataObj).then((_) => {
    (formDataObject);
    store.commit("SET_ANIMAL", null);
    router.push("/my-rodeo")
  });

  setPresaved();
}

function confirmSaveVideoPopup() {
  showSaveVideoPopup.value = true;

}

async function confirmSaveVideo() {

  mixpanel.track(UploadVideoEvent.TAP_SAVE_UPLOAD_YES, {
    "Account Type": store.state.userProfile.account_type == 1 ? "Contractor" : "Contestant",
    "Upload type": store.state.isEditVideo ? 'Edit Video' : 'Upload Recorded Video',
  });
  showSaveVideoPopup.value = false;
  const isEditVideo = store.state.isEditVideo;

  if (isEditVideo) {
    if (
      confirm(
        "Are you sure you want to save this changes? This action cannot be undone."
      )
    ) {
      confirmSaveVideoPopup
      await updateVideo();
    }

  } else {
    createVideo();
  }

}

function cancelSaveVideo() {
  mixpanel.track(UploadVideoEvent.TAP_SAVE_UPLOAD_NO, {
    "Account Type": store.state.userProfile.account_type == 1 ? "Contractor" : "Contestant",
    "Upload type": store.state.isEditVideo ? 'Edit Video' : 'Upload Recorded Video',
  });
  showSaveVideoPopup.value = false;
}

async function handleSubmit(e) {
  e.preventDefault();

  mixpanel.track(UploadVideoEvent.TAP_SAVE_UPLOAD, {
      "Account Type": store.state.userProfile.account_type == 1 ? "Contractor" : "Contestant",
      "Upload type": store.state.isEditVideo ? 'Edit Video' : 'Upload Recorded Video',
    });

  if (!valid.value) {
    setAlert(
      "error",
      "Please fill out the required fields.",
      "Validation Error"
    );
    return;
  }

  try {

    confirmSaveVideoPopup();
    mixpanel.track("Upload Video")
    mixpanel.time_event("Upload Video")
  } catch (error) {
    mixpanel.track(UploadVideoEvent.UPLOAD_FAILURE, {
      "Account Type": store.state.userProfile.account_type == 1 ? "Contractor" : "Contestant",
      "Upload type": store.state.isEditVideo ? 'Edit Video' : 'Upload Recorded Video',
      "Reason": error
    });
    (error)
    if (error.response) {
      const { data } = error.response;
      if (data.code) {
        if (data.code == 3) {
          setAlert(
            "error",
            "Videos must be 2 minutes or less. Please trim your video or upload a different video.",
            "Error on upload"
          );
        }
      }
    }
  } finally {
    uploadingProgress.value = 0;
  }
}

function initialSetup() {
  ("USER_TYPE", store.state.userProfile.account_type)
  if (store.state.isEditVideo || store.state.isFromUpload) {
    stepState.step.value = 1;
  } else {
    stepState.step.value = 0;
  }
}

onBeforeMount(() => {
  initialSetup();
});

function onBack() {
  if (store.state.isEditVideo) {
    router.push('/feed')
  } else {
    stepState.step.value = 0
  }
}
</script>

<script>
export default {
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    toggleDrawer() {
      store.dispatch('toggleDrawer');
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...';
      }
      return text;
    }
  },
  mounted() {

    this.isMobile = window.innerWidth <= 1280;
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth <= 1280;
    });
  },
}
</script>

<template>
  <v-dialog v-model="showSaveVideoPopup" max-width="500px">
    <v-card>
      <v-card-title>
        Are you sure you want to save this video?
      </v-card-title>
      <v-card-actions class="justify-space-between">
        <v-btn color="primary" @click="confirmSaveVideo">OK</v-btn>
        <v-btn color="primary" @click="cancelSaveVideo">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-app-bar app v-if="isMobile" class="centered-app-bar">
    <v-app-bar-nav-icon @click="toggleDrawer" />
    <v-app-bar-nav-icon v-html="'Upload Video'" style="margin-left: 40px;" />
  </v-app-bar>
  <v-overlay scrim="#0c0a09" class="align-center justify-center" v-model="trimming">
    <v-row class="fill-height" align-content="center" justify="center">
      <v-col v-if="!editOrUpdate" class="text-subtitle-1 text-center text-white" cols="12">
        Processing video ...
      </v-col>
      <v-col cols="24">
        <v-progress-linear color="primary" v-model="trimmingProgress" rounded :height="5"></v-progress-linear>
      </v-col>
    </v-row>
  </v-overlay>
  <v-overlay scrim="#0c0a09" class="align-center justify-center" v-model="uploading">
    <v-row class="fill-height" align-content="center" justify="center">
      <v-col v-if="editOrUpdate" class="text-subtitle-1 text-center text-white" cols="12">
        Updating...
      </v-col>
      <v-col v-if="!editOrUpdate" class="text-subtitle-1 text-center text-white" cols="12">
        Uploading video...
      </v-col>
      <v-col cols="24">
        <v-progress-linear color="primary" v-model="uploadingProgress" rounded :height="5"></v-progress-linear>
      </v-col>
    </v-row>
  </v-overlay>
  <div class="mx-auto" style="max-width: 700px;">
    <div class="d-flex flex-row mx-auto my-2">
      <v-container>
        <v-row align="center">
          <v-col cols="auto">
            <v-row justify="center">
              <v-col cols="auto">
                <v-btn :disabled="getStep == 50" variant="tonal" density="comfortable" icon="fas fa-angle-left"
                  @click="onBack"></v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-progress-linear :model-value="getStep" :height="10" color="primary" rounded></v-progress-linear>
          </v-col>
          <v-col cols="auto">
            <v-row justify="center">
              <v-col cols="auto">
                <template v-if="getStep == 50">
                  <v-btn :disabled="!store.state.videoToUpload" variant="tonal" density="comfortable"
                    icon="fas fa-angle-right" @click="(_$event) => {
    toNext();
  }
    " />
                </template>

                <template v-else>
                  <v-btn color="primary" density="comfortable" @click="(_$event) => submitBtn.click()">
                    Save
                  </v-btn>
                </template>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-form v-model="valid" @submit="handleSubmit" class="d-flex flex-column mx-auto my-4">
      <div class="mb-6">
        <div v-show="getStep === 50">
          <VideoTrimmer />
        </div>
        <!-- <img :src="thumbnailInfo.src" style="width: 200px;" /> -->
        <Form v-if="getStep === 100" />
      </div>
      <button ref="submitBtn" style="display: none" />
    </v-form>
  </div>
</template>
