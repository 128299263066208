<template>
  <h1></h1>
</template>

<script>
export default {
  name: "HomeView",

  components: {},

  setup() {},
};
</script>
