<template>

    <div>
        <img
            class="rodeonow-img"
            src="../../../assets/images/rodeonow-logo-white.png"
            alt="Logo Image">
    </div>

</template>


<script>

export default {
    name: 'LargeLogo',
    data() {
        return{
        }
    },
    created(){
    },
    methods: {
    },
}

</script>
