<script setup>
import videoPlayerModalVue from "@/components/videoPlayerModal.vue";
import Alert from "@/components/Alert.vue";
import accountTypeModalVue from "@/components/accountTypeModal.vue";
import LogOutModalVue from "@/components/LogOutModal.vue";
import contactModal from "@/components/contactModal.vue";
import { computed, ref, watch, onMounted, onBeforeUnmount } from "vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useStore } from "vuex";
import { getAuth } from "firebase/auth";
import { useRoute, useRouter } from "vue-router";
import { logOut } from "./services/authentication.service";
import switchUserModalVue from "./components/switchUserModal.vue";
import { getUserAccessibleProfiles } from "@/services/profiles";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useStepState } from "@/store/uploadVideo/step.js";
import { useAlertState } from "./store/alert";
import { getDatabase, ref as databaseRef, get, onValue } from 'firebase/database';
import mixpanel from 'mixpanel-browser';
import UploadVideoEvent, { EngamentEvent } from '@/utils/event_enum';


const auth = getAuth();
const store = useStore();
const route = useRoute();
const settingsOpen = ref(false);
const showingSnackbar = ref(false);
const router = useRouter();
const chevKey = ref(69420);
const active = ref("feed");
const db = getFirestore();
const videoInput = ref(null);
const videoInputForm = ref(null);
const stepState = useStepState();

const showSideBar = ref(true);

const database = getDatabase();

const sideBarRequied = computed(() => {
  return route.meta.sideBar;
});

const blankPage = computed(() => {
  return route.meta.blankPage;
});

const landingPage = computed(() => {
  return route.meta.landingPage;
});

const showPopup = ref(false)


watch(
  () => store.state.userProfile,
  (userProfile) => {
    getUserAccessibleProfiles(userProfile);
    if (
      userProfile &&
      (!userProfile.account_access || Object.keys(userProfile.account_access).length == 0)
    ) {
      store.commit("SET_SELECTED_PROFILE", userProfile);
    }
  }
);


store.dispatch("news");
store.dispatch("schedules");

const { setAlert } = useAlertState();


function uploadAVideo() {
  // mixpanel.track("Menu Tab", {
  //   tab: 'Upload Video',
  // })
  document.querySelector("input").addEventListener("cancel", (evt) => {
    showPopup.value = true;
    console.log(showPopup.value);
    mixpanel.track(UploadVideoEvent.CLOSE_VIDEO_UPLOAD, {
      "Account Type": store.state.userProfile.account_type == 1 ? "Contractor" : "Contestant",
      "Upload type": store.state.isEditVideo ? 'Edit Video' : 'Upload Recorded Video',
    });
  });

  openUploadVideoModalBrowser();

}

function continueUploadVideo() {
  mixpanel.track(UploadVideoEvent.CLOSE_VIDEO_UPLOAD_NO, {
      "Account Type": store.state.userProfile.account_type == 1 ? "Contractor" : "Contestant",
      "Upload type": store.state.isEditVideo ? 'Edit Video' : 'Upload Recorded Video',
    });
 
  showPopup.value = false;
  uploadAVideo();
}

function closeBrowserWindow() {
  mixpanel.track(UploadVideoEvent.CLOSE_VIDEO_UPLOAD_YES, {
      "Account Type": store.state.userProfile.account_type == 1 ? "Contractor" : "Contestant",
      "Upload type": store.state.isEditVideo ? 'Edit Video' : 'Upload Recorded Video',
    });
  showPopup.value = false;
}


function openUploadVideoModalBrowser() {
  const video = document.createElement('video');

  if (video.canPlayType('video/mp4; codecs="avc1.42E01E, mp4a.40.2"') === 'probably') {
    videoInputForm.value.reset();
    videoInput.value.click();
    mixpanel.time_event("Upload Video")
    mixpanel.track(UploadVideoEvent.OPEN_VIDEO_UPLOAD, {
      "Account Type": store.state.userProfile.account_type == 1 ? "Contractor" : "Contestant",
      "Upload type": "Upload Recorded Video",
    });
    stepState.step.value = 0;
    store.commit("SET_IS_VIDEO_EDIT", false);
    router.push("/upload");
  } else {
    setAlert('error', 'Codec is not supported in this device.');
  }
}


function videoInputChange(event) {
  event.preventDefault();
  store.commit("VIDEO_TO_UPLOAD", event.target.files[0]);
  router.push("/upload");
}

function hideMenu() {
  store.dispatch("toggleDrawer")
}
function reloadWebsite() {
  localStorage.clear();
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  }
  store.commit("RESET_STATE")
  location.reload();
  localStorage.setItem('alertShown', 'true');
}

function editProfile() {
  active.value = "editProfile";

  router.push({
    path: "/profile/edit",
    query: {
      id: store.state.selectedProfile.id,
    },
  });
}

const logout = async () => {

  store.commit("setSpinner");
  const result = await logOut().then((response) => {
    return response;
  });

  if (result.success) {
    store.commit("setSpinner");
    window.location = "/login"
  } else {
    store.commit("setSpinner");
    alert(result.error.message);
  }
};

const drawer = ref(!landingPage.value)

const submitting = computed(() => {
  return store.getters.spinner;
});

const currentUser = computed(() => {
  return auth.currentUser;
});
// eslint-disable-next-line no-unused-vars

const shouldDisplayUpload = computed(() => {
  if (store.state.userProfile != null) {
    return (store.state.userProfile.account_type ?? 0) !== 3;
  }
  else return false

})

watch(() => store.state.drawer, () => {
  drawer.value = !drawer.value;
})

watch(() => route.fullPath, () => {
  router.isReady().then(() => {
    if (landingPage.value) {
      drawer.value = false
    }
  })
})

onMounted(() => {
  console.log(currentUser.value)
  router.isReady().then(() => {
    if (route.query.play && route.query.play.length > 0) {
      getDoc(doc(db, "videos", route.query.play)).then((doc) => {
        const video = {
          ...doc.data(),
          id: doc.id,
        };
        console.log(video);
        store.commit("SET_MODAL_VIDEO", video);
        store.commit("VIDEO_PLAYER_MODAL", true);
      });
    }
  });
  const releaseRef = databaseRef(database, 'release');
  var count = 0;
  onValue(releaseRef, (snapshot) => {
    if (count != 0) {
      showingSnackbar.value = true
    }
    count += 1
  });
});

function scrollTo(to) {
  drawer.value = false
  const element = document.getElementById(to);
  element.scrollIntoView({
    behavior: "smooth",
    inline: "nearest"
  });
}


function navigateToRodeo() {

  mixpanel.track(EngamentEvent.switch_tab, {
    "Destination Tab": store.state.selectedTab,
    "Source Tab": "My Rodeo",
  })

  // mixpanel.track("Menu Tab", {
  //   tab: "My Rodeo"
  // })

  store.commit("SET_TAB", 'My Rodeo');

  router.afterEach(() => {
    let previousPath = router.options.history.state.back
    if (previousPath && route.fullPath && previousPath.includes("my-rodeo") && route.fullPath.includes("my-rodeo") && previousPath != route.fullPath) {
      window.location.reload()
    }
  });
}

function handleClickMenuTab(tabName) {

  mixpanel.track(EngamentEvent.switch_tab, {
    "Destination Tab": store.state.selectedTab,
    "Source Tab": tabName,
  })

  if(tabName == "Search") {
    mixpanel.track(EngamentEvent.open_search, {
  })
  }

  store.commit('SET_TAB', tabName);
  
  // mixpanel.track("Menu Tab", {
  //   tab: tabName, 
  // })
}
</script>

<script>

export default {
  name: "App",
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },
  beforeUnmount() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  },
 
  methods: {
    handleVisibilityChange() {
    }
  }
};
</script>

<template>
  <div class="blank" v-if="blankPage">
    <router-view />
  </div>



  <v-app v-else-if="landingPage" id="inspire" class="landing_page">
    <Alert />



    <div class="spinner-wrapper" v-if="submitting">
      <PulseLoader class="spinner" :loading="submitting" color="#ffffff"></PulseLoader>
    </div>

    <v-app-bar class="py-md-5 px-12" color="transparent" :elevation="0" absolute>
      <img crossorigin class="d-md-none ml-3" style="max-width: 123px"
        src="https://n2l640.a2cdn1.secureserver.net/wp-content/uploads/2022/01/RodeoNowLogo3.png" alt="" />

      <v-spacer></v-spacer>

      <v-btn class="d-md-none" @click="drawer = !drawer" icon>
        <v-icon>fas fa-bars</v-icon>
      </v-btn>

      <RouterLink class="mx-3 d-md-block d-none" to="/login"> Log in </RouterLink>
      <div style="height: 12px; width: 2px" class="bg-black"></div>
      <RouterLink class="mx-3 d-md-block d-none" to="/signup"> Sign up </RouterLink>

      <a class="mx-3 d-md-block d-none" href="#how_it_works" @click.prevent="scrollTo('how_it_works')"> How it works </a>
      <a class="mx-3 d-md-block d-none" href="#previews" @click.prevent="scrollTo('previews')"> Preview </a>
      <a class="mx-3 d-md-block d-none" href="#download" @click.prevent="scrollTo('download')"> Download </a>

    </v-app-bar>

    <v-navigation-drawer style="top: 0; height: 100vh; z-index: 9999" location="right" class="d-md-none" v-model="drawer"
      temporary color="#252525">
      <v-list-item class="text-white" style="display: flex; justify-content: end; background-color: #181818;">
        <v-btn icon color="transparent" @click="drawer = false" :elevation="0">
          <v-icon color="white" class="ml-auto">fas fa-close</v-icon>
        </v-btn>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item link title="How it works" class="text-white" href="#how_it_works"
        @click.prevent="scrollTo('how_it_works')"></v-list-item>
      <v-divider></v-divider>
      <v-list-item link title="Preview" class="text-white" href="#previews"
        @click.prevent="scrollTo('previews')"></v-list-item>
      <v-divider></v-divider>
      <v-list-item link title="Download" class="text-white" href="#download"
        @click.prevent="scrollTo('download')"></v-list-item>
      <v-divider></v-divider>
      <v-list-item link title="Log In" class="text-white" to="/login"></v-list-item>
      <v-divider></v-divider>
      <v-list-item link title="Sign up" class="text-primary" to="/signup"></v-list-item>
    </v-navigation-drawer>

    <v-main class="pt-md-0">
      <router-view />

    </v-main>
  </v-app>

  <v-app v-else id="inspire">
    <Alert />
    <!-- <v-alert v-if="alertShow" dense :type="alertType">
      {{ alertText }}
    </v-alert> -->

    <div class="spinner-wrapper" v-if="submitting">
      <PulseLoader class="spinner" :loading="submitting" color="#ffffff"></PulseLoader>
    </div>


    <v-navigation-drawer left v-model="drawer" v-if="sideBarRequied" ref="showSideBar">

      <v-list-item class="title">
        <img src="/assets/images/rodeo-text-logo.png" width="240" style="object-fit: cover" />
      </v-list-item>
      <!-- <v-list-item class="title d-flex justify-center">
        <img
          src="/assets/images/rodeo-logo.png"
          height="96"
          style="object-fit: cover"
        />
      </v-list-item> -->

      <v-divider :thickness="0.7" class="main"></v-divider>

      <v-list v-if="currentUser != null" density="compact" nav class="main">
        
        <RouterLink to="/feed" class="custom-list-item" active-class="active" @click="() => handleClickMenuTab('Feed') ">
          <img :src="require('./assets/icons/glyph/glyphs/house.png')" width="20" height="20" alt="" />
          <h4>Feed</h4>
        </RouterLink>

        <RouterLink to="/top-rides" class="custom-list-item" active-class="active" @click="() => handleClickMenuTab('Top Rides') ">
          <img :src="require('./assets/icons/glyph/glyphs/icon_top_rides.png')" width="20" height="20" alt="" />
          <h4>Top Rides</h4>
        </RouterLink>

        <RouterLink to="/search" class="custom-list-item" active-class="active" @click="() => handleClickMenuTab('Search') ">
          <img :src="require('./assets/icons/glyph/glyphs/magnifyingglass.png')" width="20" height="20" alt="" />
          <h4>Search</h4>
        </RouterLink>
        <RouterLink to="/news" class="custom-list-item" active-class="active" @click="() => handleClickMenuTab('News') ">
          <img :src="require('./assets/icons/glyph/glyphs/doc.plaintext.png')" width="20" height="20" alt="" />
          <h4>News</h4>
        </RouterLink>
        <RouterLink to="/schedules" class="custom-list-item" active-class="active" @click="() => handleClickMenuTab('Schedule') ">
          <img :src="require('./assets/icons/glyph/glyphs/calendar.png')" width="20" height="20" alt="" />
          <h4>Schedule</h4>
        </RouterLink>

        <div v-if="shouldDisplayUpload" @click="uploadAVideo"
          :class="[{ active: $route.path == '/upload' }, 'custom-list-item']">
          <img :src="require('./assets/icons/glyph/glyphs/arrow.up.circle.png')" width="20" height="20" alt="" />
          <h4>Upload Video</h4>
        </div>
 
        <RouterLink to="/notifications" class="custom-list-item" active-class="active"  @click="() => handleClickMenuTab('Notifications') ">
          <img :src="require('./assets/icons/glyph/glyphs/bell.png')" width="20" height="20" alt="" />
          <h4>Notifications</h4>
        </RouterLink>

        <RouterLink v-if="$store.state.selectedProfile" @click="navigateToRodeo"
          :to="{ name: 'myrodeo', query: { userId: store.state.userProfile.id } }" :class="`custom-list-item ${$route.fullPath.includes('animals') ? 'active' : ''
            }`" active-class="active">
          <img :src="require('./assets/icons/glyph/glyphs/photo.on.rectangle.png')" width="20" height="20" alt="" />
          <h4>My Rodeo</h4>
        </RouterLink>
      </v-list>


      <v-list v-if="currentUser == null" density="compact" nav class="main">
        <RouterLink to="/login" class="custom-list-item" active-class="active">
          <h4>Log in</h4>
        </RouterLink>
        <RouterLink to="/signup" class="custom-list-item" active-class="active">

          <h4>Sign up</h4>
        </RouterLink>
      </v-list>



      <template v-if="currentUser" v-slot:append>
        <div :class="settingsOpen ? 'v-openSetting' : 'v-closeSetting'" class="settingsWrapper">
          <v-divider class="main"></v-divider>
          <div @click="
            settingsOpen = !settingsOpen;
          chevKey++;
          " v-ripple class="d-flex justify-center py-2 chevron" :key="chevKey">
            <v-icon color="#141414">
              {{ settingsOpen ? "fas fa-chevron-down" : "fas fa-chevron-up" }}
            </v-icon>
          </div>

          <v-divider v-if="settingsOpen" class="main"></v-divider>
          <v-list class="main">
            <div @click="$store.commit('SWITCH_USER_MODAL', true)" class="custom-list-item"
              v-if="$store.state.selectedProfile">
              <v-avatar size="36" class="mr-3">
                <img crossorigin style="height: 56px; width: auto" :src="$store.state.selectedProfile.photo_url" alt="" />
              </v-avatar>
              <h4>
                {{ $store.state.selectedProfile.first_name }}
                {{ $store.state.selectedProfile.last_name }}
              </h4>
            </div>

            <div class="custom-list-item" :class="active == 'editProfile' ? 'active' : 'inactive'" @click="editProfile">
              <!-- <v-icon class="mr-3" small color="black">fas fa-user</v-icon> -->
              <h4>Edit Profile</h4>
            </div>

            <RouterLink to="/profile/change-password" class="custom-list-item"
              :class="active == 'changePassword' ? 'active' : 'inactive'" @click="active = 'changePassword'">
              <h4>Change Password</h4>
            </RouterLink>

            <RouterLink to="/profile/grant-access" class="custom-list-item"
              :class="active == 'grantAccAccess' ? 'active' : 'inactive'" @click="active = 'grantAccAccess'">
              <h4>Grant Account Access</h4>
            </RouterLink>

            <RouterLink to="/profile/event" class="custom-list-item"
              :class="active == 'upcomingEvent' ? 'active' : 'inactive'" @click="active = 'upcomingEvent'">
              <h4>Upcoming Event</h4>
            </RouterLink>

            <div class="custom-list-item" @click="$store.commit('CHANGE_ACC_TYPE_MODAL', true)">
              <h4>Change Account Type</h4>
            </div>

            <contactModal />

            <div class="custom-list-item" @click="$store.commit('SWITCH_USER_MODAL', true)">
              <!-- <v-icon class="mr-3" small color="black">fas fa-right-left</v-icon> -->
              <h4>Switch User</h4>
            </div>

            <div class="custom-list-item" @click="$store.commit('LOGOUT_MODAL', true)">
              <h4>Logout</h4>
            </div>
          </v-list>
        </div>
      </template>
    </v-navigation-drawer>
    <!-- <v-app-bar density="compact" :height="88" v-if="sideBarRequied" dense :elevation="0" :border="true">
      <template v-slot:prepend>
      </template>
    </v-app-bar> -->

    <v-main>
      <router-view />
    </v-main>

    <form ref="videoInputForm" @submit.prevent>
      <input @change="videoInputChange" type="file" accept="video/mp4,video/x-m4v,video/quicktime,video/*"
        style="display: none" ref="videoInput" />
    </form>

    <v-dialog v-model="showPopup" max-width="500px">
      <v-card>
        <v-card-title>
          Are you sure you want to discard?
        </v-card-title>
        <v-card-actions class="justify-space-between">
          <v-btn color="primary" @click="closeBrowserWindow">OK</v-btn>
          <v-btn color="primary" @click="continueUploadVideo">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <switchUserModalVue v-if="$store.state.userProfile"></switchUserModalVue>
    <videoPlayerModalVue v-if="$store.state.modalVideo"></videoPlayerModalVue>
    <accountTypeModalVue></accountTypeModalVue>
    <LogOutModalVue @logout="logout" />

    <!-- <v-btn
      @click="scrollToTop"
      v-show="showScroller"
      class="floating-action"
      color="primary"
      icon
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M4.5 15.75l7.5-7.5 7.5 7.5"
        />
      </svg>
    </v-btn> -->
  </v-app>
  <div>
    <v-snackbar v-model="showingSnackbar" location="right bottom" :timeout="10000">
      There is a new version available. Click to reload RodeoNow.
      <template v-slot:actions>
        <v-btn color=red variant="text" @click="reloadWebsite">
          Refresh
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>


<style lang="scss">
@import "theme/variable.scss";

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.floating-action {
  position: fixed;
  bottom: 3vw;
  right: 3vw;
}

.floating-action svg {
  width: 34px;
}

.v-list-item__prepend {
  display: none !important;
}

a {
  text-decoration: none;
}

.v-openSetting {
  transform: translateY(-311px);
}

.v-navigation-drawer__append {
  height: 100px !important;
  overflow: visible !important;
}

.v-closeSetting {
  transform: translateY(5px);
}

.settingsWrapper {
  background: #f6f8fa;
  transition: transform 0.5s ease-out;
}

.custom-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  // margin-bottom: 10px;

  border-bottom: 1px solid #bcb6bc;

  &>img {
    margin-right: 20px;
    border-radius: 5px;
  }

  &>h4 {
    font-size: 12px;
    color: #141414;
    margin: 0;
  }

  &:first-child {
    border-top: 1px solid #bcb6bc;
  }
}

.custom-list-item:hover {
  cursor: pointer;
  background: #c1c1c1;
}

.active {
  background: #c1c1c1;
}

.v-navigation-drawer {
  background: #f6f8fa;
}

.v-list.main {
  background: transparent;
  padding: 0;
  // color: #fff;
}

.v-divider.main {
  color: #fff;
}

.v-list-item.title {
  padding: 6px 8px 0px 8px !important;
}

.v-list-item:not(.title) {
  color: rgb(0, 0, 0);
  font-size: 12px;

  &>.svg-inline--fa {
    font-size: 12px;
  }
}

.v-list-item:not(.profile) {
  padding: 15px;
}

.v-list-item.profile:hover {
  cursor: pointer;
}

.spinner-wrapper {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background: #00000085;
  /* top: 0; */
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rbtn:hover {
  cursor: pointer;
  color: var(--RODEONOW_RED);
}

.multiselect {
  border: none !important;
  border-bottom: 1px solid #000 !important;
}

.multiselect-option {
  font-size: 12px;
}

.multiselect-tag {
  background: var(--RODEONOW_RED) !important;
  font-size: 12px !important;
}

.v-app-bar-title {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 600;
}

.chevron {
  transition: 0.25s ease-in-out;
}

.chevron:hover {
  background-color: #bcb6bc;
}

.text--disabled {
  color: grey;
}

.landing_page {
  max-width: 100vw;
  overflow: hidden;
}
</style>

