<template>
  <v-app-bar app v-if="isMobile" class="centered-app-bar">
    <v-app-bar-nav-icon @click="toggleDrawer" />
    <v-app-bar-nav-icon v-html="'News'"  />
  </v-app-bar>
  <v-container>
    <v-row align="start" gutters v-if="news">
      <v-col v-for="item of news" :key="item.story_id" class="custom-col">

        <v-card class="mx-auto card" :max-width="360" >

          <img crossorigin :src="getUrl(item.story_image)" style="height: 200px;" cover />

          <v-card-title> {{ item.story_headline }} </v-card-title>
          <v-card-text style="padding-bottom: 40px;">
            <span v-html="isMobile ? truncateText(item.story_summary, 100) : item.story_summary"></span>
          </v-card-text>

          <v-card-actions :style="isMobile ? 'font-size: 12px;' : ''">
            <div style="padding-left: 8px;">{{ isMobile ? item.story_postdate.substring(0, 10) : item.story_postdate }}</div>
            <v-btn @click="gotoPage(item.story_path)" class="readMore" variant="text">
              {{'Read more' }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div icon class="scrollTop" @click="scrollTop" v-if="newsIndex > 1">
      <img :src="require('@/assets/icons/glyph/glyphs/arrow.up.circle.fill.png')" alt="" />
    </div>
    <!-- <div class="spinner-wrapper" v-if="isLoading"> -->
    <PulseLoader class="loader-spinner" :loading="isLoading" :color="'#000000'"></PulseLoader>
    <!-- </div> -->
  </v-container>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { getNews } from "@/services/news.service";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import store from "@/store";

export default {
  name: "NewsView",

  components: { PulseLoader },


  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    toggleDrawer() {
      store.dispatch('toggleDrawer');
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...';
      }
      return text;
    }
  },
  mounted() {

    this.isMobile = window.innerWidth <= 1280;
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth <= 1280;
    });
  },



  setup() {
    const windowTop = ref(window.top.scrollY);
    const store = useStore();
    const isLoading = ref(false);
    const newsIndex = ref(1);
    const news = computed(() => store.getters.news);
    console.log(news);
    const getUrl = (path) => {
      const CORS_PROXY = `https://corsproxy.io/?`;
      // console.log(path);
      return CORS_PROXY + encodeURIComponent(process.env.VUE_APP_BASE_URL + path);
    };
    const gotoPage = (path) => {
      console.log(path);
      const url = "https://prorodeo.com" + path;
      console.log(url);
      var anchor = document.createElement("a");
      anchor.href = url;
      anchor.target = "_blank";
      anchor.click();
    };
    const getNextSet = async () => {
      isLoading.value = true;
      newsIndex.value++;
      const response = await getNews(newsIndex.value);
      isLoading.value = false;
      if (!response.error) {
        console.log(response.data);
        store.commit("addNews", response.data);

        console.log();
      }
    };

    const scrollTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const onScroll = (e) => {
      windowTop.value = e.target.scrollTop;
      if (
        window.innerHeight + window.scrollY >=
        document.body.scrollHeight - 10
      ) {
        getNextSet();
      }
    };
    onMounted(() => {
      window.addEventListener("scroll", onScroll, true);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("scroll", onScroll, true);
    });
    return {
      news,
      getUrl,
      scrollTop,
      isLoading,
      gotoPage,
      newsIndex,
    };

  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.v-card {
  max-height: 500px;
  min-height: 500px;
}

.v-card-title {
  line-height: 1.5rem;
  font-size: 1.1rem;
}

.v-card-text {
  font-size: 0.8rem;
}

.readMore {
  color: var(--RODEONOW_RED);
}

.scrollTop {
  position: fixed;
  bottom: 20px;
  z-index: 99999;
  right: 20px;
  background: var(--RODEONOW_RED);
  padding: 10px;
  border-radius: 50%;
  display: flex;
}

.scrollTop:hover {
  cursor: pointer;
}

.loader-spinner {
  text-align: center;
}

.v-card-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: space-between;
}


@media (max-width: 1280px){
    .readMore {
      font-size: 12px;
    }

    .card {
      min-height: 300px;
    }
} 
</style>
