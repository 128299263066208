<script setup>
import { onMounted, ref } from "vue";
import Footer from '@/components/landing-page/Footer.vue'
const loading_overlay = ref(false);

// onMounted(() => {
//   window.addEventListener("DOMContentLoaded", () => {
//     console.log("Ran properly ✅")
//     loading_overlay.value = false;
//   });
//   setTimeout(() => {
//     if(loading_overlay.value) {
//       console.log("Fix this 😛")
//       loading_overlay.value = false;
//     }
//   }, 3000);
// });
</script>

<template>
  <div>
    <v-overlay
      class="align-center justify-center"
      scrim="grey-darken-3"
      persistent
      v-model="loading_overlay"
    >
      <v-progress-circular
        :size="70"
        :width="4"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <div style="width: auto; height: 100vh; align-self: center; align-items: center; display: flex; justify-content: center; background-color: #eee;">
      <v-img 
        :aspect-ratio="1"
        :src="require('../assets/images/rodeo-logo.png')"
        max-height="100vh"
        style="width: 50%; height: auto; margin-top: 10vh;"
      ></v-img>
    </div>

    <section id="download" style="height: auto;" class="py-16">
      <v-container>
        <v-row no-gutters>
          <v-col class="v-col-12 v-col-md-6">
            <img
              class="d-block mx-auto mb-16 mb-md-0"
              style="max-width: 100%; height: auto; width: 362px"
              :src="require('../assets/images/signup-560x1024.png')"
              alt=""
            />
          </v-col>

          <v-col
            class="v-col-12 v-col-md-6 d-flex flex-column align-center justify-center"
          >
            <p
              class="text-h3 text-center mb-8"
              style="line-height: 1.3; font-weight: light"
            >
              Now available for iOS & Android! On Demand Rodeo Arena Action delivered to
              your device!
            </p>

            <a href="https://apps.apple.com/us/app/rodeonow/id1610408539" class="mt-5">
              <img
                style="max-width: 300px; width: 100%"
                :src="require('../assets/images/app-store-image-300x95.png')"
                alt=""
              />
            </a>

            <a href="https://play.google.com/store/apps/details?id=com.rodeonow.rodeonow">
              <img
                style="max-width: 350px; width: 100%"
                :src="require('../assets/images/google-play-badge.png')"
                alt=""
              />
            </a>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="text-center bg-grey-lighten-3 py-16" id="">
      <v-container class="d-flex flex-column align-center">
        <h2 class="mb-4 text-h4">ON DEMAND ARENA ACTION</h2>

        <div style="height: 2px; width: 30px" class="bg-black mb-4"></div>

        <p class="mb-6">
          Your one stop rodeo livestock app with all the on demand rodeo action you could
          ask for! Want to see videos of your favorite horse, bull, or even contestant?
          This is the place! Made for contestants, stock contractors, and fans of rodeo.
        </p>

        <p class="mb-6">
          It’s this simple…search your favorite horse, bull, or rodeo contestant in any
          event and see any video from any rodeo anywhere!
        </p>

        <p class="mb-6">
          Want to record or upload your own videos? Join RodeoNow and have an instant
          access to anything rodeo anytime. Members can record directly from the app and
          share your video to your social media. It’s truly a one stop shop!
        </p>

        <p class="mb-6">
          Are you a contestant? Want to know about your latest stock draw? Need to know
          how much rein to give your bronc? How did your steer run last rodeo? Find all
          that info right here at RodeoNow.
        </p>
      </v-container>
    </section>

    <section id="how_it_works" style="height: auto" class="py-16 text-center">
      <h2 class="text-center py-8 text-h3">How it works?</h2>

      <v-row align-content="center" class="px-sm-8 px-md-16">
        <v-col
          sm="12"
          md="4"
          class="v-col-12 v-col-md-4 d-flex flex-column justify-space-around align-center"
        >
          <div class="d-flex flex-column justify-center align-center mb-10">
            <v-icon color="#6c90d0" size="x-large">fas fa-user</v-icon>
            <p class="text-h6 py-3">All views are Free</p>
            <p class="text-center">
              Download the app and view unlimited rodeo action videos of horses, bulls,
              timed event cattle, and cowboys!
            </p>
          </div>

          <div class="d-flex flex-column justify-center align-center mb-10">
            <v-icon color="#6c90d0" size="x-large">fas fa-video</v-icon>
            <p class="text-h6 py-3">Record and Categorize your Videos</p>
            <p class="text-center">
              Download the app and view unlimited rodeo action videos of horses, bulls,
              timed event cattle, and cowboys!
            </p>
          </div>
        </v-col>
        <v-col class="v-col-12 v-col-md-4"
          ><img
            class="mx-auto d-block mb-10"
            style="max-width: 100%; height: auto; width: 288px"
            :src="require('../assets/images/signup-560x1024.png')"
            alt=""
        /></v-col>
        <v-col
          class="d-flex v-col-12 v-col-md-4 flex-column justify-space-around align-center"
        >
          <div class="d-flex flex-column justify-center align-center mb-10">
            <v-icon color="#6c90d0" size="x-large">fas fa-dollar</v-icon>
            <p class="text-h6 py-3">Membership</p>
            <p class="text-center">
              Stock contractors, contestants, and fans can upload, save, view, and link
              their videos right from the app, anywhere, anytime.
            </p>
          </div>

          <div class="d-flex flex-column justify-center align-center mb-10">
            <v-icon color="#6c90d0" size="x-large">fas fa-video</v-icon>
            <p class="text-h6 py-3">Enjoy Unlimited Rodeo Action</p>
            <p class="text-center">
              Use our simple search by brand, name, stock contractor, or rider. You’ll
              find action packed videos right at your fingertips.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="bg-grey-darken-3 py-16" id="previews">
      <h2 class="text-center py-8 text-h3">RodeoNow Video Preview</h2>

      <v-container class="">
        <v-row>
          <v-col class="v-col-12 v-col-md-6">
            <v-responsive :aspect-ratio="16 / 9">
              <iframe
                class=""
                credentialless
                style="width: 100%; height: 100%"
                src="https://www.youtube.com/embed/qyCEm4libUk"
                title="Saddle Bronc Riding"
                frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </v-responsive>
          </v-col>
          <v-col class="v-col-12 v-col-md-6">
            <v-responsive :aspect-ratio="16 / 9">
              <iframe
                class=""
                credentialless
                style="width: 100%; height: 100%"
                src="https://www.youtube.com/embed/MKBlUjf8KJQ"
                title="Saddle Bronc Riding"
                frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </v-responsive>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <Footer />
  </div>
</template>

<style scoped>
section {
  width: 100vw;
}

.v-overlay__scrim {
    opacity: 100% !important;
}
</style>
