const animalTypes = [
  "Bulls", "Horses", "Steers", "Calfs"
];

const parseAnimalTypes = (arr = []) => {
  return arr.map((type) => animalTypes.at(type == 0 ? 0 : type - 1)).join(", ");
};

export { parseAnimalTypes };

export default animalTypes;
