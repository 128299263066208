<script setup>
import { ref, computed, toRaw, onBeforeMount, watch, onBeforeUpdate } from "vue";
import { computedAsync } from "@vueuse/core";
import {
  getFirestore,
  getDoc,
  doc,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import store from "@/store/index.js";
import { useEventState } from "@/store/event.js";
import { form, setContestant } from "@/store/uploadVideo/form.js";
import { handlers } from "@/store/uploadVideo/handlers.js";
import { setAnimal, animal } from "@/store/uploadVideo/animal.js";
import { useRouter } from "vue-router";
import FormCalendar from "../FormCalendar.vue";
import events from "@/utils/events";
import animalTypes from "@/utils/animal_types";
import { useTypesense } from "@/plugins/typesense";
import { getProfileImageById } from "@/services/profiles";
import iconImage from "@/assets/images/thumb_rodeonow-1024x1024.png";


const { client } = useTypesense();

const router = useRouter();
const db = getFirestore();

const upcomingEvent = useEventState();

const searchAnimal = ref("");
const loadingAnimals = ref(false);

const searchContestant = ref("");
const loadingContestants = ref(false);

const noAccessUsers = ref(false);
const accessUsersData = ref([]);

const selectedProfile = computed(() => {
  return store.state.selectedProfile;
});

// TODO: refactor these
const animalsDialog = ref(false);
const contestantsDialog = ref(false);
const showPopup = ref(false)
const showAddCustomeAnimalPopup = ref(false);
const loadingContestantAnimals = ref(false);
const selectedTabAnimal = ref(0);
const selectedAnimalOwnTab = ref(0);
const animalsCount = ref(0);
const searchAnimalInput = ref();

const nameHelper = (account) =>
  account.first_name != "" || account.first_name != null
    ? `${account.first_name} ${account.last_name}`
    : account.name;

const selectedAccessUserData = computed(() => {
  let record = accessUsersData.value.find(
    (user) => user.id === handlers.selectedAccessUser
  );

  if (record) {
    return record;
  } else {
    return null;
  }
});

const accountType = computed(() => {
  return selectedAccessUserData.value ? selectedAccessUserData.value.account_type : 0;
});

function addNewAnimal() {
  store.commit("SET_ANIMAL_FROM_UPLOAD", true);
  router.push({
    path: "/animals/new",
  });
  console.log(selectedAnimalOwnTab.value)
}


const animals = computedAsync(() => {
  searchAnimal.value; // do not remove
  let id = store.state.userProfile.id;

  if (selectedAccessUserData.value) {
    id = selectedAccessUserData.value.id;
  }

  if (accountType.value == 2) {
    if (selectedAnimalOwnTab.value !== 0) {
      searchAnimalInput.value.focus();
      return [];
    }
  }

  selectedTabAnimal.value;
  loadingAnimals.value = true;
  let docRef = query(collection(db, "animals"), where("contractor", "==", id));
  return getDocs(docRef)
    .then((snapshot) => {
      let cont_animals = snapshot.docs
        .map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        .filter((animal) => {
          return (
            selectedTabAnimal.value == 0 || animal.animal_type == selectedTabAnimal.value
          );
        })
        .filter((animal) => {
          return ["name", "brand", "contractor_name"]
            .map((k) => _filterSearch(animal, k, searchAnimal.value))
            .includes(true);
        });

      cont_animals = cont_animals.sort((a, b) => a.name.localeCompare(b.name));

      cont_animals = cont_animals.map((animal) => {
        return {
          ...animal,
          title: `${animal.name} (${animal.brand})`,
        };
      });
      loadingAnimals.value = false;
      animalsCount.value = cont_animals.length;
      return cont_animals;
    })
    .catch((error) => {
      console.error(error);
      loadingAnimals.value = false;
      return [];
    });
});

const contestants = computedAsync(() => {
  if (accountType.value != 1) {
    return [];
  }
  if (form.contestants_id == null) {
    if (searchContestant.value === "") {
      return [];
    }
  }

  loadingContestants.value = true;

  let search = {
    q: searchContestant.value,
    query_by: "location,first_name,last_name",
    filter_by: "account_type:=2",
    sort_by: "_text_match:desc",
  };

  return client
    .collections("users")
    .documents()
    .search(search)
    .then(async (res) => {
      const { found } = res;

      if (found) {
        let cont_contestants = res.hits.map((hit) => hit.document);

        cont_contestants = cont_contestants.sort((a, b) =>
          a.first_name.localeCompare(b.first_name)
        );
        console.log(cont_contestants);

        cont_contestants = cont_contestants.map(async (contestant) => {
          return {
            ...contestant,
            title: `${contestant.first_name} ${contestant.last_name}`,
            photo_url: await getProfileImageById({
              id: contestant.id,
              account_type: contestant.account_type,
            }),
          };
        });

        return await Promise.allSettled(cont_contestants).then(results => {
          return results.map((res) => res.value);
        })
      }
    })
    .finally(() => {
      loadingContestants.value = false;
    }).catch(console.error);
}, []);

const animalImage = (item) => {
  if (item.picture_url && item.picture_url !== "") {
    return item.picture_url;
  } else if (store.state.userProfile.photo_url) {
    return store.state.userProfile.photo_url;
  } else {
    return iconImage;
  }
};

const contestantAnimals = computedAsync(() => {
  if (accountType.value != 2) {
    return [];
  }
  if (searchAnimal.value === "") {
    return [];
  }
  if (selectedAnimalOwnTab.value !== 1) {
    return [];
  }

  loadingContestantAnimals.value = true;

  let search = {
    q: searchAnimal.value,
    query_by: "name,brand,contractor_name",
    sort_by: "_text_match:desc",
  };

  let data = client
    .collections("animals")
    .documents()
    .search(search)
    .then((res) => {
      const { found } = res;

      if (found) {
        let cont_animals = res.hits.map((hit) => hit.document);

        cont_animals = cont_animals.sort((a, b) => a.name.localeCompare(b.name));
        console.log(cont_animals);

        cont_animals = cont_animals.map((animal) => {
          return {
            ...animal,
            title: `${animal.name} (${animal.brand})`,
          };
        });

        animalsCount.value = cont_animals.length;
        return cont_animals;
      } else {
        return [];
      }
    })
    .finally(() => {
      loadingContestantAnimals.value = false;
    });

  return data;
}, []);

function setSelectAccessUsers() {
  // let keys = Object.keys(store.state.userProfile.user_access).filter(
  //   (x) => store.state.userProfile.user_access[x]
  // );
  console.log(store.state.userProfile.account_access);

  let accountsIDS = []

  if (store.state.userProfile?.account_access) {
    accountsIDS = Object.keys(store.state.userProfile.account_access);
  }

  if (!accountsIDS.length) {
    noAccessUsers.value = true;
  }

  if (!accountsIDS.includes(selectedProfile.value.id)) {
    accountsIDS = [selectedProfile.value.id, ...accountsIDS];
  }

  let promises = accountsIDS.map((accountID) => {
    return getDoc(doc(db, "users", accountID));
  });

  Promise.allSettled(promises)
    .then((results) => {
      // [Methods]
      const logged = (acc) => acc.id === selectedProfile.value.id;

      // [Data]
      let mappedUsers = results.map((res) => ({
        ...res.value.data(),
        id: res.value.id,
      }));

      accessUsersData.value = mappedUsers.map((user) => {
        const { account_type, id, first_name, last_name } = user;
        return {
          account_type,
          id,
          first_name,
          last_name,
          mapped_name: nameHelper(user),
        };
      });

      let data = toRaw(accessUsersData.value);

      let withoutLoggedUsers = data.filter((acc) => !logged(acc));

      try {
        withoutLoggedUsers.sort((a, b) => a.name.localeCompare(b.name));
      } catch (err) {
        withoutLoggedUsers.sort((a, b) => a.first_name.localeCompare(b.first_name));
      }

      const loggedUser = data.filter(logged);

      accessUsersData.value = [...loggedUser, ...withoutLoggedUsers];

      handlers.selectedAccessUser = loggedUser.at(0).id;
    })
    .catch(console.error);
}

function getTextByIndex(index) {
  if (index >= 1 && index <= events.length) {
    return events[index - 1];
  } else {
    return "Index out of range";
  }
}

function formatDate(timestamp) {
  const date = new Date(timestamp * 1000);

  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
}

function mapAnimalAfterCreate() {
  const animal = store.state.animal;
  console.log("UPLOAD", store.state.animal)
  if (animal != null || animal != undefined) {
    Object.assign(animal, {
      brand: animal.brand,
      name: animal.name,
      id: animal.animalID,
      tagged_animal_contractor_id: animal.tagged_animal_contractor_id,
    });
    Object.assign(handlers, {
      selectedAnimal: `${animal.name} (${animal.brand})`,
    });
  }


}

function loadPresaved() {
  if (store.state.isFromUpload) {
    mapAnimalAfterCreate();
    store.commit("SET_ANIMAL_FROM_UPLOAD", false);
    return;
  }
  const videoEditData = store.state.videoToEdit;
  let selectedAnimal;
  let selectedContestantName;
  let selectedContestantId;
  if (store.state.isEditVideo) {
    const title = videoEditData.title;
    const location = videoEditData.location;
    const event_date = formatDate(videoEditData.event_date);
    if (videoEditData.animal_id != "" && videoEditData.animal_id != undefined) {
      selectedAnimal = `${videoEditData.animal_name} (${videoEditData.animal_brand})`
      let id = videoEditData.animal_id;
      let name = selectedAnimal;
      let brand = videoEditData.animal_brand;
      let tagged_animal_contractor_id = videoEditData.tagged_animal_contractor_id;
      store.commit("SET_ANIMAL", {
        id,
        name,
        brand,
        tagged_animal_contractor_id
      })
      Object.assign(animal, {
        brand,
        name: videoEditData.animal_name,
        id,
        tagged_animal_contractor_id,
      });
    }
    const selectedEvent = getTextByIndex(videoEditData.event_type);
    const score = videoEditData.score;
    const time = videoEditData.time;
    const notes = videoEditData.notes;

    if (typeof videoEditData.contestants_names === 'object' && Array.isArray(videoEditData.contestants_names)) {
      selectedContestantName = videoEditData.contestants_names[0];
      Object.assign(handlers, {
        selectedContestant: selectedContestantName,
      });
      Object.assign(form, {
        contestants_names: selectedContestantName,
      });
    }

    if (typeof videoEditData.contestants_id === 'object' && Array.isArray(videoEditData.contestants_id)) {
      selectedContestantId = videoEditData.contestants_id[0];

      Object.assign(form, {
        contestants_id: selectedContestantId,
      });

      console.log("VIDEO EDIT", selectedContestantId)
    }

    console.log("VIDEO EDIT", form)

    let scoreTime = "score";
    if (score == null && time != null) {
      scoreTime = "time";
    }
    Object.assign(handlers, {
      selectedEvent,
      scoreTime,
      selectedAnimal,
      notes
    });
    Object.assign(form, {
      event_date,
      location,
      score,
      time,
      title,
      notes,
    });
    console.log("VIDEO EDIT", form);

  } else {
    let rawPresaved = toRaw(upcomingEvent.value);
    const {
      event: title,
      eventType: selectedEvent,
      date: event_date,
      location,

    } = rawPresaved;

    Object.assign(form, {
      event_date,
      location,
      title,
      score: null,
      time: null,

    });
    Object.assign(handlers, {
      selectedEvent,
      scoreTime: events.indexOf(selectedEvent) < 3 ? "score" : "time",
    });
  }


}

function initialSetup() {
  if (!selectedProfile.value) return;
  setSelectAccessUsers();

  if (store.state.animalSelected != null) {
    Object.assign(store.state.animalSelected, {
      title: `${store.state.animalSelected.name} (${store.state.animalSelected.brand})`
    })
    store.commit("SET_ANIMAL", store.state.animalSelected);
    handlers.selectedAnimal = `${store.state.animalSelected.name} (${store.state.animalSelected.brand})`
  } else {
    loadPresaved();
  }

  // _loadVideoPreview();
  // if (route.query.selectedAccessUser) setDataFromAnimalsPage();
}

function selectAnimal(animal) {
  if (handlers.selectedAnimal == animal.title) {
    handlers.selectedAnimal = "";
    return
  }
  handlers.selectedAnimal = animal.title;
  store.commit("SET_ANIMAL", animal)
  animalsDialog.value = false;
  setAnimal(animals.value);
}

function selectContestant(contestant) {
  if (handlers.selectedContestant == contestant.title) {
    handlers.selectedContestant = "";
    return
  }
  handlers.selectedContestant = contestant.title;
  contestantsDialog.value = false;
}

function handleConfirmContestant() {
  Object.assign(form, {
    contestants_id: null,
  })

  Object.assign(handlers, {
    selectedContestant: form.contestants_names,
  });

  showPopup.value = false;
  contestantsDialog.value = false;
}

function handleConfirmCustomAnimal() {

  const animalCustom = handlers.inputCustomAnimal;

  store.commit("SET_ANIMAL", {
    id: null,
    name: animalCustom.animal_name,
    brand: animalCustom.animal_brand,
    contractor_name: animalCustom.contractor_name,
  })
  Object.assign(animal, {
    brand: animalCustom.animal_brand,
    name: animalCustom.animal_name,
    id: null,

  });

  Object.assign(handlers, {
    selectedAnimal: `${animalCustom.animal_name} (${animalCustom.animal_brand})`,

  });

  handlers.inputCustomAnimal.animal_brand = null;
  handlers.inputCustomAnimal.animal_name = null;
  handlers.inputCustomAnimal.contractor_name = null;

  showAddCustomeAnimalPopup.value = false;
  animalsDialog.value = false;
}


function _filterSearch(obj, key, term) {
  try {
    return obj[key].toLowerCase().includes(term.toLowerCase());
  } catch (error) {
  }
}

watch(
  () => handlers.selectedEvent,
  (v) => {
    if (v !== null && v !== undefined) {
      handlers.scoreTime = events.indexOf(v) < 3 ? "score" : "time";
    }
  }
);

watch(

  () => {
    setContestant(contestants.value);
  }
);

watch(selectedProfile, (v) => {
  if (v) {
    initialSetup();
  }
});

watch(selectedAccessUserData, () => {
  if ((store.state.animalSelected != undefined || store.state.animalSelected != null) && store.state.animalSelected.animalID == null) {
    handlers.selectedAnimal = null
  }
  if (!store.state.isEditVideo) {
    form.contestants_id = null;
    handlers.selectedContestant = null;

  }
});

onBeforeMount(() => {
  initialSetup();
});
</script>

<template>

  <v-dialog v-model="showSaveVideoPopup" max-width="500px">
    <v-card>
      <v-card-title>
        Are you sure you want to save this video?
      </v-card-title>
      <v-card-actions class="justify-space-between">
        <v-btn color="primary" @click="closeBrowserWindow">OK</v-btn>
        <v-btn color="primary" @click="continueUploadVideo">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-container>
    <template v-if="!noAccessUsers">
      <v-row>
        <v-col>
          <v-autocomplete v-model="handlers.selectedAccessUser" :close-on-click="false" :items="accessUsersData"
            :rules="[(v) => !!v || 'User is required!']" item-title="mapped_name" item-value="id" label="User"
            :readonly="store.state.isEditVideo ? true : false" variant="underlined" />
        </v-col>
      </v-row>
    </template>
    <v-row>
      <v-col>
        <v-text-field v-model="form.title" label="Rodeo Event" variant="underlined" hide-no-data hide-selected
          class="py-0" :rules="[(v) => !!v || 'Rodeo Event is required!']" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field v-model="form.location" label="Location" hide-no-data hide-selected
          :rules="[(v) => !!v || 'Location is required!']" variant="underlined" class="py-0" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <FormCalendar v-model="form.event_date" label="Event Date" hide-no-data hide-selected variant="underlined"
          :rules="[(v) => !!v || 'Event Date is required!']" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select v-model="handlers.selectedEvent" :items="[...events]" label="Select Event" variant="underlined"
          :rules="[(v) => !!v || 'Event Select Event is required!']">
        </v-select>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="auto">
        <v-btn-toggle v-model="handlers.scoreTime" tile color="primary" group mandatory disabled>
          <v-btn size="small" value="score"> Score </v-btn>
          <v-btn size="small" value="time"> Time </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col alignSelf="start">
        <template v-if="handlers.scoreTime == 'score'">
          <v-text-field v-model="form.score" type="number" label="Score" hide-details hide-no-data variant="underlined" />
        </template>
        <template v-else>
          <v-text-field v-model="form.time" type="number" label="Time" hide-details hide-no-data variant="underlined" />
        </template>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col>

        <v-dialog hide-overlay v-model="animalsDialog" transition="dialog-bottom-transition">
          <template v-slot:activator="{ on, attrs }">

            <v-text-field label="Animal in Video" variant="underlined" v-model="handlers.selectedAnimal" v-bind="attrs"
              append-inner-icon="fas fa-caret-down" v-on="on"
              :rules="[(v) => (store.state.userProfile.account_type === 2 || !!v) || 'Animal is a required field']"
              @click="animalsDialog = true"></v-text-field>
          </template>
          <v-card class="form_dialog">
            <v-toolbar dark color="primary">
              <v-toolbar-title>Select Animal</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="animalsDialog = false"> Close</v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-list three-line style="padding-left: 1.5%; padding-right: 1.5%">
              <v-tabs v-if="selectedProfile.account_type == 2" v-model="selectedAnimalOwnTab" color="primary">
                <v-tab>My Animals</v-tab>
                <v-tab>Other animals</v-tab>
              </v-tabs>

              <v-tabs v-else v-model="selectedTabAnimal" color="primary">
                <v-tab>All</v-tab>
                <v-tab v-for="(tab, index) in animalTypes" :key="index">{{ tab }}</v-tab>
              </v-tabs>

              <v-text-field ref="searchAnimalInput" v-model="searchAnimal" density="compact" autofocus
                prepend-inner-icon="fas fa-search" color="white" hide-no-data hide-selected hide-details
                variant="outlined" placeholder="Search" return-object class="py-0 my-3">
              </v-text-field>

              <div class="d-flex justify-center" style="width: 100%"
                v-if="loadingContestantAnimals || loadingAnimals || loadingContestants">
                <v-progress-circular class="mx-auto" indeterminate></v-progress-circular>
              </div>

              <v-card flat v-for="(item, index) in selectedAnimalOwnTab === 1
                  ? contestantAnimals
                  : animals" :key="index" class="d-flex flex-column px-3"
                :color="handlers.selectedAnimal == item.title ? `#f0e2e2` : 'transparent'" @click="selectAnimal(item)">
                <div v-if="item" class="d-flex py-3">
                  <v-avatar size="100" class="mr-3" cover tile style="border-radius: 5%">
                    <img crossorigin height="100" width="100" :src="animalImage(item)"
                      onerror="this.onerror=null; this.src='/assets/images/thumb_rodeonow-1024x1024.png'" />
                  </v-avatar>

                  <div class="d-flex flex-column text--black">
                    <div style="width: 100%; display: block" class="d-flex">
                      <span class="text-subtitle-1 text--disabled mr-1">{{
                        item.brand
                      }}</span>
                      <span class="text-subtitle-1 font-weight-normal">{{
                        item.name
                      }}</span>
                    </div>

                    <div style="width: 100%; display: block">
                      <span class="text-subtitle-1 text-red mr-1">{{
                        !!item.events ? item.events.map((e) => events[e]).join(", ") : ''
                      }}</span>
                    </div>
                  </div>
                </div>
                <v-divider class="flex-none" style="width: 100%; display: block"></v-divider>
              </v-card>
              <div style="margin-left: 80px;"
                v-if="selectedAnimalOwnTab === 0 && animals.length === 0 && !(loadingContestantAnimals || loadingAnimals || loadingContestants)">
                <v-btn color="primary" variant="text" density="comfortable" icon="fas fa-plus-circle"
                  @click="addNewAnimal">
                  <img height="32" :src="require('@/assets/icons/glyph/glyphs/plus.circle.red.png')" />
                  <div>
                    <span class="add-text">Add New Animal</span>
                  </div>
                </v-btn>

              </div>
              <div style="margin-left: 80px;"
                v-if="selectedAnimalOwnTab === 1 && contestantAnimals.length === 0 && !(loadingContestantAnimals || loadingAnimals || loadingContestants)">
                <v-btn color="primary" variant="text" density="comfortable" icon="fas fa-plus-circle"
                  @click="showAddCustomeAnimalPopup = true">
                  <img height="32" :src="require('@/assets/icons/glyph/glyphs/plus.circle.red.png')" />
                  <div>
                    <span class="add-text">Add Custom Animal</span>
                  </div>
                </v-btn>

              </div>
              <v-dialog v-model="showAddCustomeAnimalPopup" max-width="500px">
                <v-card>
                  <v-card-title>
                    Add Animal
                  </v-card-title>

                  <v-card-text>
                    <v-text-field v-model="handlers.inputCustomAnimal.animal_name" label="Animal name" />
                    <v-text-field v-model="handlers.inputCustomAnimal.animal_brand" label="Animal brand" />
                    <v-text-field v-model="handlers.inputCustomAnimal.contractor_name" label="Contractor name" />
                  </v-card-text>

                  <v-card-actions class="justify-space-between">
                    <v-btn color="primary" @click="showAddCustomeAnimalPopup = false">Cancel</v-btn>
                    <v-btn color="primary" @click="handleConfirmCustomAnimal">Confirm</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-list>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <template v-if="accountType == 1">
      <v-row align="center">
        <v-col>
          <v-dialog hide-overlay v-model="contestantsDialog" transition="dialog-bottom-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field label="Contestant" variant="underlined" v-model="handlers.selectedContestant" v-bind="attrs"
                append-inner-icon="fas fa-caret-down" v-on="on" @click="contestantsDialog = true"
                hide-details></v-text-field>
            </template>

            <v-card class="form_dialog">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Select Contestant</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn dark text @click="contestantsDialog = false"> Close</v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-list three-line style="padding-left: 1.5%; padding-right: 1.5%">
                <v-text-field v-model.lazy="searchContestant" density="compact" prepend-inner-icon="fas fa-search"
                  color="white" hide-no-data hide-selected hide-details variant="outlined" placeholder="Search"
                  return-object class="py-0 my-3">
                </v-text-field>

                <div class="d-flex justify-center" style="width: 100%" v-if="loadingContestants">
                  <v-progress-circular class="mx-auto" indeterminate></v-progress-circular>
                  <!-- <PulseLoader class="spinner" :loading="true" color="#ffffff"></PulseLoader> -->
                </div>

                <v-card flat v-for="(item, index) in contestants" :key="index" class="d-flex flex-column px-3"
                  @click="selectContestant(item)" :color="handlers.selectedContestant == item.title ? `#f0e2e2` : 'transparent'
                    ">
                  <div v-if="item" class="d-flex py-3">
                    <v-avatar size="100" class="mr-3" cover tile style="border-radius: 5%">
                      <img crossorigin :src="item.photo_url" cover />
                    </v-avatar>

                    <div class="d-flex flex-column text--black">
                      <div style="width: 100%; display: block" class="d-flex">
                        <span class="text-subtitle-1 text--disabled mr-1">{{
                          item.title
                        }}</span>
                      </div>

                      <div style="width: 100%; display: block">
                        <span class="text-subtitle-1 text-red mr-1">{{
                          !!item.events ? item.events.map((e) => events[e]).join(", ") : ''
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <v-divider class="flex-none" style="width: 100%; display: block"></v-divider>
                </v-card>
                <div style="margin-left: 120px;">
                  <v-btn
                    v-if="(contestants == null ? [] : contestants) && (contestants ?? []).length === 0 && !loadingContestants && handlers.selectedContestant != ''"
                    color="primary" variant="text" density="comfortable" icon="fas fa-plus-circle"
                    @click="showPopup = true">
                    <div class="button-container">
                      <img height="32" :src="require('@/assets/icons/glyph/glyphs/plus.circle.red.png')" />
                      <div class="add-text">
                        <div style="text-align: left;">Can't find?</div>
                        <div style="text-align: left;">Click to add contestant</div>
                      </div>

                    </div>
                  </v-btn>
                </div>

              </v-list>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-col alignSelf="end" cols="auto" v-if="accountType == 1">

        <v-dialog v-model="showPopup" max-width="500px">
          <v-card>
            <v-card-title>
              Add Contestant
            </v-card-title>

            <v-card-text>
              <v-text-field v-model="form.contestants_names" label="Contestant Name" />
            </v-card-text>

            <v-card-actions class="justify-space-between">
              <v-btn color="primary" @click="showPopup = false">Cancel</v-btn>
              <v-btn color="primary" @click="handleConfirmContestant">Confirm</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </template>
    <v-row>
      <v-col>
        <v-textarea v-model="form.notes" label="Notes" variant="underlined" hide-no-data hide-selected class="py-0" />
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.v-autocomplete.v-select--is-menu-active .v-input__icon--append #unrotate .v-icon {
  transform: none;
}

.form_dialog {
  width: 100% !important;
  margin-left: auto;
  margin-right: auto;
  min-height: 75vh;
}

@media screen and (min-width: 992px) {
  .form_dialog {
    width: 40% !important;
  }
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.add-text {
  margin-left: 8px;
}
</style>
