<template>
    <section
      class="bg-grey-darken-4 text-white pa-3 text-body-2 text-center text-md-left"
    >
      <v-container>
        <p class="text-grey mb-1">
          © 2023 RodeoNow | Site By
          <a href="https://rodeowebdesign.com/" class="text-grey-lighten-3">Rodeo Web Design</a>
        </p>
        <p>
          <a class="text-grey" href="/privacy-policy">Privacy Policy</a> |
          <a class="text-grey" href="/terms-and-conditions">Terms & Conditions</a>
        </p>
      </v-container>
    </section>
</template>